<template>
  <div>
    <div class="batch_t">
      <div class="branch_l">
        <img
          src="@/assets/structure/close.png"
          class="batch_close"
          @click="close"
        />
        <div>请假</div>
      </div>
      <div class="branch_bto">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="switcOperation">确认</el-button>
      </div>
    </div>

    <div class="batch_c">
      <el-form
        ref="batch"
        label-position="top"
        label-width="80px"
        :model="formLabelAlign"
        :rules="rules"
      >
        <el-form-item label="员工" prop="ids">
          <div class="unableChange">{{ name.join(",") }}</div>
        </el-form-item>
        <el-form-item label="请假类型" prop="typeRest">
          <el-select v-model="formLabelAlign.typeRest" style="width: 100%">
            <el-option
              v-for="item in leaveType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间" prop="timeBegin">
          <el-cascader
            v-model="formLabelAlign.timeBegin"
            filterable
            :options="options"
            style="width: 100%"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="结束时间" prop="timeEnd">
          <el-cascader
            v-model="formLabelAlign.timeEnd"
            filterable
            :options="options"
            style="width: 100%"
          ></el-cascader>
        </el-form-item>
        <div v-if="isError" style="color: red">{{ error }}</div>
        <el-form-item label="请假时长(天)" prop="ids">
          <div class="unableChange">{{ difference }}</div>
        </el-form-item>
        <el-form-item label="请假事由" prop="description">
          <el-input
            type="textarea"
            :rows="4"
            v-model="formLabelAlign.description"
            maxlength="100"
            show-word-limit
          >
          </el-input>
        </el-form-item>
        <el-form-item label="审批人">
          <div class="unableChange">{{ approvedBy }}</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import http from "../../../../utils/http";
export default {
  data() {
    return {
      formLabelAlign: {}, //提交的表单
      difference: "", //小时差
      handler: {}, //经办人
      approvedBy: "", //审批人
      submitArr: [], //考勤数据
      isError: false, //是否能提交
      clockDataList: [], //休息时间
      error: "", //错误提示
      rules: {
        ids: [
          {
            type: "array",
            required: true,
            message: "请选择至少一个用户",
            trigger: "blur",
          },
        ],
        typeRest: [
          {
            required: true,
            message: "请选择请假类型",
            trigger: "change",
          },
        ],
        timeBegin: [
          {
            required: true,
            message: "请选择开始时间",
            trigger: "change",
          },
        ],
        timeEnd: [
          {
            required: true,
            message: "请选择结束时间",
            trigger: "change",
          },
        ],
        description: [
          { required: true, message: "请输入出差事由", trigger: "blur" },
        ],
      },
      leaveType: [
        {
          label: "事假",
          value: 0,
        },
        {
          label: "年假",
          value: 1,
        },
        {
          label: "婚假",
          value: 2,
        },
        {
          label: "产休假",
          value: 3,
        },
        {
          label: "病假",
          value: 4,
        },
        {
          label: "调休",
          value: 5,
        },
        {
          label: "其他",
          value: 6,
        },
      ],
    };
  },
  props: ["name", "id"],
  created() {
    this.formLabelAlign.ids = this.id;

    var date = this.getAllDatesBetween();
    this.options = date;

    var user = localStorage.getItem("userinfo");
    user = JSON.parse(user);
    this.formLabelAlign.userExec = user.id;
    this.approvedBy = user.name;

    // 获取当前时间
    this.getCurrentDate(2);
  },
  watch: {
    formLabelAlign: {
      handler(newValue, oldValue) {
        if (newValue) {
          if (newValue.timeBegin.length > 1 && newValue.timeEnd.length > 1) {
            this.isWithintheshift();
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    // 关闭弹窗
    close() {
      this.$refs.batch.resetFields();
      this.$emit("close");
    },

    // 确认弹窗
    switcOperation() {
      this.$refs.batch.validate((valid) => {
        if (valid) {
          var value = JSON.stringify(this.formLabelAlign);
          value = JSON.parse(value);
          value.type = 2;
          value.status = 1;
          value.timeExec = this.getCurrentDate(1);

          if (this.isError) {
            return;
          }

          var beginDay = "";
          var endDay = "";
          if (this.submitArr.length > 0) {
            var first = this.submitArr[0];
            var last = this.submitArr[this.submitArr.length - 1];
            if (
              value.timeBegin[0] == value.timeEnd[0] &&
              value.timeBegin[1] == value.timeEnd[1]
            ) {
              if (value.timeBegin[1] == 0) {
                if (first.arrange == -4) {
                  beginDay = 0;
                  endDay = 43200;
                } else {
                  var firstDate = this.submitArr[0].bmsAttendanceArrangeGetVO;
                  var endDate =
                    this.submitArr[this.submitArr.length - 1]
                      .bmsAttendanceArrangeGetVO;
                  // 上午
                  beginDay =
                    firstDate.arrangeDataList[0].clockData.beginDaySecond;
                  var end =
                    endDate.arrangeDataList[endDate.arrangeDataList.length - 1]
                      .clockData.endDaySecond;
                  endDay = (end + beginDay) / 2;
                }
              } else {
                if (first.arrange == -4) {
                  beginDay = 43200;
                  endDay = 86340;
                } else {
                  var firstDate = this.submitArr[0].bmsAttendanceArrangeGetVO;
                  var endDate =
                    this.submitArr[this.submitArr.length - 1]
                      .bmsAttendanceArrangeGetVO;
                  // 下午
                  var begin =
                    firstDate.arrangeDataList[0].clockData.beginDaySecond;
                  var end =
                    endDate.arrangeDataList[endDate.arrangeDataList.length - 1]
                      .clockData.endDaySecond;
                  beginDay = (end + begin) / 2;
                  endDay = end;
                }
              }
            } else {
              if (value.timeBegin[1] == 0) {
                if (first.arrange == -4) {
                  beginDay = 0;
                } else {
                  var firstDate = this.submitArr[0].bmsAttendanceArrangeGetVO;
                  // 上午
                  beginDay =
                    firstDate.arrangeDataList[0].clockData.beginDaySecond;
                }
              } else {
                if (first.arrange == -4) {
                  beginDay = 43200;
                } else {
                  var firstDate = this.submitArr[0].bmsAttendanceArrangeGetVO;
                  // 下午
                  var start =
                    firstDate.arrangeDataList[0].clockData.beginDaySecond;
                  var end =
                    firstDate.arrangeDataList[
                      firstDate.arrangeDataList.length - 1
                    ].clockData.endDaySecond;
                  beginDay = (end + start) / 2;
                }
              }

              if (value.timeEnd[1] == 0) {
                if (last.arrange == -4) {
                  endDay = 43200;
                } else {
                  var endDate =
                    this.submitArr[this.submitArr.length - 1]
                      .bmsAttendanceArrangeGetVO;
                  // 上午
                  var start =
                    endDate.arrangeDataList[0].clockData.beginDaySecond;
                  var end =
                    endDate.arrangeDataList[endDate.arrangeDataList.length - 1]
                      .clockData.endDaySecond;
                  endDay = (end + start) / 2;
                }
              } else {
                if (last.arrange == -4) {
                  endDay = 86399;
                } else {
                  // 下午
                  var endDate =
                    this.submitArr[this.submitArr.length - 1]
                      .bmsAttendanceArrangeGetVO;
                  endDay =
                    endDate.arrangeDataList[endDate.arrangeDataList.length - 1]
                      .clockData.endDaySecond;
                }
              }
            }
          }

          // 判断是上午还是下午
          var string = "";
          string = value.timeBegin[1] + "" + value.timeEnd[1];

          var timeBegin = ""; //开始时间
          var timeEnd = ""; //结束时间

          // 判断时间是否在休息时间范围内
          if (this.clockDataList.length > 0) {
            var startClock = this.clockDataList[0];
            var endClock = this.clockDataList[this.clockDataList.length - 1];
            // 判断开始时间
            if (startClock.beginDaySecond) {
              if (
                beginDay >= startClock.beginDaySecond &&
                beginDay <= startClock.endDaySecond
              ) {
                timeBegin =
                  value.timeBegin[0] +
                  " " +
                  this.convertSecondsToTimeFormat(startClock.endDaySecond) +
                  ":00";
              } else {
                timeBegin =
                  value.timeBegin[0] +
                  " " +
                  this.convertSecondsToTimeFormat(beginDay) +
                  ":00";
              }
            } else {
              timeBegin =
                value.timeBegin[0] +
                " " +
                this.convertSecondsToTimeFormat(beginDay) +
                ":00";
            }

            // 判断结束时间
            if (endClock.beginDaySecond) {
              if (
                endDay >= endClock.beginDaySecond &&
                endDay <= endClock.endDaySecond
              ) {
                timeEnd =
                  value.timeEnd[0] +
                  " " +
                  this.convertSecondsToTimeFormat(endClock.beginDaySecond) +
                  ":00";
              } else {
                timeEnd =
                  value.timeEnd[0] +
                  " " +
                  this.convertSecondsToTimeFormat(endDay) +
                  ":00";
              }
            } else {
              timeEnd =
                value.timeEnd[0] +
                " " +
                this.convertSecondsToTimeFormat(endDay) +
                ":00";
            }
          } else {
            timeBegin =
              value.timeBegin[0] +
              " " +
              this.convertSecondsToTimeFormat(beginDay) +
              ":00";
            timeEnd =
              value.timeEnd[0] +
              " " +
              this.convertSecondsToTimeFormat(endDay) +
              ":59";
          }

          // 判断是上午下午组成新的字符串
          var beginString = "";
          var endString = "";
          if (value.timeBegin[1] == 0) {
            beginString = value.timeBegin[0] + " " + "上午";
          } else {
            beginString = value.timeBegin[0] + " " + "下午";
          }
          if (value.timeEnd[1] == 0) {
            endString = value.timeEnd[0] + " " + "上午";
          } else {
            endString = value.timeEnd[0] + " " + "下午";
          }

          console.log(timeBegin);
          console.log(timeEnd);

          // 判断小时数是否大于24
          value.timeBegin = this.adjustDateForOverTime(timeBegin);
          value.timeEnd = this.adjustDateForOverTime(timeEnd);

          value.description =
            value.description +
            "(" +
            beginString +
            "," +
            endString +
            ")" +
            this.difference;
          return;

          http.post("/bmsApply/add", value).then((res) => {
            if (res.code == 200) {
              this.$message.success("登记成功");
              this.close();
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 获取当前时间
    getCurrentDate(type) {
      var now = new Date();

      // 获取年份
      var year = now.getFullYear();

      // 获取月份（注意：月份是从0开始的，所以我们需要加1）
      var month = now.getMonth() + 1;
      month = month < 10 ? "0" + month : month; // 如果月份是一位数，前面补零

      // 获取日期
      var date = now.getDate();
      date = date < 10 ? "0" + date : date; // 如果日期是一位数，前面补零

      // 获取小时
      var hours = now.getHours();
      hours = hours < 10 ? "0" + hours : hours; // 如果小时是一位数，前面补零

      // 获取分钟
      var minutes = now.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes; // 如果分钟是一位数，前面补零
      // 秒
      var seconds = now.getSeconds();
      seconds = seconds < 10 ? "0" + seconds : seconds;

      // 将获取到的年月日时分拼接成字符串
      var currentDateTime =
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;

      var currentDate = year + "-" + month + "-" + date;

      if (type == 1) {
        return currentDateTime;
      } else {
        this.$set(this.formLabelAlign, "timeBegin", [currentDate]);
        this.$set(this.formLabelAlign, "timeEnd", [currentDate]);
      }
    },

    // 处理判断是否大于23时59分
    adjustDateForOverTime(dateTimeStr) {
      // 分割日期时间字符串
      var [datePart, timePart] = dateTimeStr.split(" ");
      var [year, month, day] = datePart.split("-").map(Number);
      var [hours, minutes, seconds = 0] = timePart.split(":").map(Number);

      // 如果小时数大于23，则日期增加一天，并减去多余的小时数
      if (hours > 23) {
        day++;
        hours %= 24; // 取余得到正确的小时数
      }

      // 创建一个新的Date对象
      var date = new Date(year, month - 1, day, hours, minutes, seconds); // 注意月份是从0开始的

      // 格式化日期时间字符串
      var formattedDate = `${date.getFullYear()}-${padZero(
        date.getMonth() + 1
      )}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(
        date.getMinutes()
      )}:${padZero(date.getSeconds())}`;

      // 返回格式化的日期时间字符串
      return formattedDate;

      // 辅助函数：在数字前面补零
      function padZero(num) {
        return num.toString().padStart(2, "0");
      }
    },

    // 标准时间转换成yyyy-mm-dd
    formatDate(standardTime) {
      const date = new Date(standardTime);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    // 相隔小时差
    calculateHourDifference(dateStr1, dateStr2) {
      const date1 = new Date(dateStr1);
      const date2 = new Date(dateStr2);

      // 计算时间差，单位为毫秒
      const timeDiff = Math.abs(date2 - date1);

      // 将毫秒转换为小时
      const hourDiff = (timeDiff / (1000 * 60 * 60)).toFixed(1);

      return hourDiff + "h";
    },
    // 获取近两年每个月份
    getAllDatesBetween() {
      var arr = [];
      let startDate = new Date();
      startDate.setFullYear(startDate.getFullYear(), 0, 1); // 设置为今年1月1日
      let endDate = new Date(startDate);
      endDate.setFullYear(endDate.getFullYear() + 2);
      let dates = [];
      let currentDate = new Date(startDate);
      let newendDate = new Date(endDate);

      while (currentDate <= newendDate) {
        // 如果不需要时间部分，可以只取年月日
        let year = currentDate.getFullYear();
        let month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 月份从0开始，补零
        let day = String(currentDate.getDate()).padStart(2, "0"); // 补零
        let formattedDate =
          year === new Date().getFullYear()
            ? `${month}月${day}日`
            : `${year}年${month}月${day}日`;
        dates.push(formattedDate);

        // 增加一天
        currentDate.setDate(currentDate.getDate() + 1);
      }

      dates.map((item) => {
        arr.push({
          value: this.convertDateStringToISOFormat(item),
          label: item,
          children: [
            {
              value: "0",
              label: "上午",
            },
            {
              value: "1",
              label: "下午",
            },
          ],
        });
      });
      return arr;
    },
    // 请假判断
    isWithintheshift() {
      if (this.formLabelAlign.timeBegin[0] && this.formLabelAlign.timeEnd[0]) {
        if (this.formLabelAlign.timeEnd[0] < this.formLabelAlign.timeBegin[0]) {
          this.error = "结束时间必须大于开始时间";
          this.isError = true;
          return false;
        } else {
          if (
            this.formLabelAlign.timeEnd[0] == this.formLabelAlign.timeBegin[0]
          ) {
            if (
              this.formLabelAlign.timeEnd[1] < this.formLabelAlign.timeBegin[1]
            ) {
              {
                this.error = "结束时间必须大于开始时间";
                this.isError = true;
                return false;
              }
            }
          }
        }
        http
          .get("/bmsAttendanceRecord/search", {
            user: this.id[0],
            beginDay: this.formLabelAlign.timeBegin[0],
            endDay: this.formLabelAlign.timeEnd[0],
            pageNum: 1,
            pageSize: 10,
          })
          .then((res) => {
            if (res.code == 200) {
              var arr = res.data.list;
              if (arr.length > 0) {
                this.handleAtten(arr);
              } else {
                this.error = "申请时间不在考勤班次内";
                this.isError = true;
              }
            }
          });
      }
    },
    // 处理判断是否在考勤时间内
    handleAtten(arr) {
      if (arr) {
        var newArr = [];
        arr.map((item) => {
          if (item.bmsAttendanceArrangeGetVO) {
            item.bmsAttendanceArrangeGetVO.arrangeDataList.map((citem) => {
              if (citem.clockDataList.length > 0) {
                var array = citem.clockDataList;
              } else {
                var array = [{}];
              }
              newArr = [...newArr, ...array];
            });
          }
        });
        this.clockDataList = newArr;
        if (
          (arr[0].arrange < 0 && arr[0].arrange != -4) ||
          (arr[arr.length - 1].arrange < 0 && arr[arr.length - 1].arrange != -4)
        ) {
          //   开始或结束时间不在考勤班次内
          this.error = "申请时间必须在班次时间段内";
          this.isError = true;
          return;
        } else {
          // 开始和结束时间都在考勤班次内
          // 如果只有一条数据
          if (arr.length == 1) {
            if (
              this.formLabelAlign.timeBegin[1] == this.formLabelAlign.timeEnd[1]
            ) {
              this.difference = 0.5;
              this.submitArr = [arr[0]];
              this.isError = false;
            } else {
              var day = this.daysBetweenDates(
                this.formLabelAlign.timeBegin[0],
                this.formLabelAlign.timeEnd[0]
              );

              this.difference = day;
              this.submitArr = [arr[0]];
              this.isError = false;
            }
          } else {
            var day = this.daysBetweenDates(
              this.formLabelAlign.timeBegin[0],
              this.formLabelAlign.timeEnd[0]
            );
            var newArr = arr.slice(1, arr.length - 1);
            newArr.map((item) => {
              if (item.arrange < 0) {
                if (item.arrange != -4) {
                  day = day - 1;
                }
              }
            });

            if (this.formLabelAlign.timeBegin[1] == 1) {
              day = day - 0.5;
            }
            if (this.formLabelAlign.timeEnd[1] == 0) {
              day = day - 0.5;
            }

            this.difference = day;
            this.submitArr = arr;
            this.isError = false;
          }
        }
      }
    },
    // 处理日期
    convertDateStringToISOFormat(dateString) {
      // 提取年份
      let yearMatch = dateString.match(/\d{4}年?/);
      let year = yearMatch
        ? yearMatch[0].replace("年", "")
        : new Date().getFullYear();

      // 提取月份和日期
      let monthDayMatch = dateString.match(/(\d+月)(\d+日)/);
      if (!monthDayMatch) {
        // 如果匹配失败，说明格式不正确，返回原始字符串
        return dateString;
      }

      let month = monthDayMatch[1].replace("月", "");
      let day = monthDayMatch[2].replace("日", "");

      // 如果月份或日期只有一位数，前面补0
      month = month.padStart(2, "0");
      day = day.padStart(2, "0");

      // 拼接成YYYY-MM-DD格式
      let isoDateString = `${year}-${month}-${day}`;
      return isoDateString;
    },
    // 判断两个时间相隔几天
    daysBetweenDates(dateString1, dateString2) {
      const date1 = new Date(dateString1 + "T00:00:00"); // 开始时间设置为当天的0点
      const date2 = new Date(dateString2 + "T23:59:59"); // 结束时间设置为当天的23:59:59
      const diffInMs = date2 - date1;
      const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24)); // 转换为天数并向上取整
      return diffInDays;
    },
    // 秒转成时间
    convertSecondsToTimeFormat(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);

      const hoursStr = hours.toString().padStart(2, "0");
      const minutesStr = minutes.toString().padStart(2, "0");

      return `${hoursStr}:${minutesStr}`;
    },
  },
};
</script>

<style scoped>
.branch_l {
  display: flex;
  align-items: center;
}
.batch_t {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 18px;
  color: #1a1a1a;
  border-bottom: 1px solid #cfd4e8;
}
.batch_close {
  width: 24px;
  cursor: pointer;
  height: 24px;
  margin-right: 20px;
}
.batch_c {
  padding: 0 30px;
  box-sizing: border-box;
  margin-top: 40px;
  overflow-y: scroll;
}
.batch_c::-webkit-scrollbar {
  display: none;
}
.department_search_inp {
  width: 100%;
  border: 1px solid #dcdfe6;
}
::v-deep .depa .el-select-dropdown {
  top: 330px !important;
}
::v-deep .batch_c .el-autocomplete {
  width: 100%;
}
.unableChange {
  width: 413px;
  height: 40px;
  border: 1px solid #d0d0d0;
  padding-left: 17px;
  box-sizing: border-box;
  line-height: 40px;
  font-size: 16px;
  color: #1a1a1a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
