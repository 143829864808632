import Vue from "vue";
import VueRouter from "vue-router";
import Comment from "../views/common.vue";
import Device from "../views/device/device.vue";
import Distribute from "../views/device/distribute.vue";
import DeviceMember from "../views/device/member.vue";
import Record from "../views/record/record.vue";
import Onsite from "../views/record/onsite.vue";
import Structure from "../views/structure";
import DepartmentManagement from "../views/structure/department.vue";
import Login from "../views/login";
import Updatepass from "../views/account/updatepass.vue";
import Loginmethod from "../views/account/loginmethod.vue";
import Role from "../views/account/role";
import Visitor from "../views/invite/visitor.vue";
import Approve from "../views/invite/approve.vue";
import Group from "../views/attendance/groupManage.vue";
import addgroup from "../views/attendance/addGroup.vue";
import Classes from "../views/attendance/classes/index.vue";
import Chockin from "../views/attendance/record/chockin.vue";
import Personal from "../views/attendance/record/personal.vue";
import Personalsummary from "../views/attendance/record/personalsummary.vue";
import Department from "../views/attendance/record/department.vue";
import Departmentsummary from "../views/attendance/record/departmentsummary.vue";
import schedule from "../views/attendance/schedule.vue";
import EventApproval from "../views/approval/event.vue";
import PersionalApproval from "../views/approval/personnel.vue";
import Help from "../views/account/help.vue";
import Aboutus from "../views/account/aboutus.vue";
import Businesstrips from "../views/attendance/register/businesstrips.vue";
import Goout from "../views/attendance/register/goout.vue";
import Leave from "../views/attendance/register/leave.vue";
import Replacement from "../views/attendance/register/replacement.vue";
import Workovertime from "../views/attendance/register/workovertime.vue";
import Bulletin from "../views/bulletin/index.vue";
import Choosecompany from "../views/login/choosecompany.vue";
import Original from "../views/attendance/record/original.vue";
import Wechat from "../views/attendance/wechat/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },

  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "登录",
    },
  },
  {
    path: "/choosecompany",
    name: "Choosecompany",
    component: Choosecompany,
    meta: {
      title: "选择企业",
    },
  },
  {
    path: "/comment",
    redirect: "/bulletin",
    component: Comment,
    children: [
      {
        path: "/bulletin",
        name: "Bulletin",
        component: Bulletin,
        meta: {
          title: "数据看板",
        },
      },
      {
        path: "/structure",
        name: "Structure",
        component: Structure,
        meta: {
          title: "组织架构",
        },
      },
      {
        path: "/DepartmentManagement",
        name: "DepartmentManagement",
        component: DepartmentManagement,
        meta: {
          title: "部门管理",
        },
      },
      {
        path: "/eventApproval",
        name: "eventApproval",
        component: EventApproval,
        meta: {
          title: "事件审批",
        },
      },
      {
        path: "/persionalApproval",
        name: "persionalApproval",
        component: PersionalApproval,
        meta: {
          title: "人员审批",
        },
      },
      {
        path: "/group",
        name: "Group",
        component: Group,
        meta: {
          title: "考勤组管理",
        },
      },
      {
        path: "/addgroup",
        name: "addgroup",
        component: addgroup,
        meta: {
          title: "新建考勤组",
        },
      },
      {
        path: "/schedule",
        name: "schedule",
        component: schedule,
        meta: {
          title: "排班",
        },
      },
      {
        path: "/classes",
        name: "classes",
        component: Classes,
        meta: {
          title: "班次设置",
        },
      },
      {
        path: "/wechat",
        name: "wechat",
        component: Wechat,
        meta: {
          title: "小程序打卡",
        },
      },
      {
        path: "/businesstrips",
        name: "businesstrips",
        component: Businesstrips,
        meta: {
          title: "登记出差",
        },
      },
      {
        path: "/goout",
        name: "goout",
        component: Goout,
        meta: {
          title: "登记外出",
        },
      },
      {
        path: "/leave",
        name: "leave",
        component: Leave,
        meta: {
          title: "登记请假",
        },
      },
      {
        path: "/replacement",
        name: "replacement",
        component: Replacement,
        meta: {
          title: "登记补卡",
        },
      },
      {
        path: "/workovertime",
        name: "workovertime",
        component: Workovertime,
        meta: {
          title: "登记加班",
        },
      },
      {
        path: "/original",
        name: "original",
        component: Original,
        meta: {
          title: "原始记录表",
        },
      },
      {
        path: "/chockin",
        name: "chockin",
        component: Chockin,
        meta: {
          title: "打卡记录表",
        },
      },
      {
        path: "/personal",
        name: "personal",
        component: Personal,
        meta: {
          title: "个人考勤明细",
        },
      },
      {
        path: "/personalsummary",
        name: "personalsummary",
        component: Personalsummary,
        meta: {
          title: "个人考勤汇总",
        },
      },
      {
        path: "/department",
        name: "department",
        component: Department,
        meta: {
          title: "部门考勤明细",
        },
      },
      {
        path: "/departmentsummary",
        name: "departmentsummary",
        component: Departmentsummary,
        meta: {
          title: "部门考勤汇总",
        },
      },
      {
        path: "/device",
        name: "Device",
        component: Device,
        meta: {
          title: "设备管理",
        },
      },
      {
        path: "/deviceMember",
        name: "DeviceMember",
        component: DeviceMember,
        meta: {
          title: "设备用户管理",
        },
      },
      {
        path: "/distribute",
        name: "Distribute",
        component: Distribute,
        meta: {
          title: "下发任务列表",
        },
      },
      {
        path: "/record",
        name: "Record",
        component: Record,
        meta: {
          title: "通行记录",
        },
      },
      {
        path: "/onsite",
        name: "Onsite",
        component: Onsite,
        meta: {
          title: "在场人员",
        },
      },
      {
        path: "/loginmethod",
        name: "Loginmethod",
        component: Loginmethod,
        meta: {
          title: "登录方式",
          keepAlive: false,
        },
      },
      {
        path: "/updatepass",
        name: "Updatepass",
        component: Updatepass,
        meta: {
          title: "修改密码",
        },
      },
      {
        path: "/role",
        name: "Role",
        component: Role,
        meta: {
          title: "角色权限",
        },
      },
      {
        path: "/help",
        name: "Help",
        component: Help,
        meta: {
          title: "帮助",
        },
      },
      {
        path: "/aboutus",
        name: "Aboutus",
        component: Aboutus,
        meta: {
          title: "关于我们",
        },
      },
    ],
  },

  // h5页面
  {
    path: "/invite",
    name: "invite",
    component: Visitor,
    meta: {
      title: "访客信息",
    },
  },
  {
    path: "/visit",
    name: "visit",
    component: Approve,
    meta: {
      title: "访客信息",
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
