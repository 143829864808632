<template>
  <div class="schedule common-style">
    <div class="schedule_head">
      <span style="color: #b2b2b2">考勤管理 / 考勤组管理 </span>
      <span style="color: #5c5c5c">/ 排班</span>
    </div>
    <div class="schedule_wrapper">
      <div class="schedule_search_wrapper">
        <span @click="backGroup" style="cursor: pointer">
          <img src="@/assets/returnback.png" class="title_icon" />
        </span>
        <span class="group_back" @click="backGroup" style="cursor: pointer"
          >返回
        </span>
        <div class="schedule_add">
          <el-button @click="cancelSchedule">取消</el-button>
          <el-button @click="confirmSchedule" type="primary">保存</el-button>
        </div>
      </div>
      <div class="schedule_table_wrapper">
        <div class="sch_title">
          <div class="sch_title_label">考勤班次</div>
          <span
            v-if="this.selectedClassSelection.length == 0"
            @click="editClasses"
            style="cursor: pointer"
          >
            <i class="el-icon-edit"></i>
            <span style="margin-left: 8px; color: #9a9a9a">配置班次</span>
          </span>
          <div v-else class="sch_title_d" @click="editClasses">
            <div
              v-for="item in selectedClassSelection"
              class="sch_title_span"
              :key="item.id"
            >
              <div v-if="item.bmsAttendanceArrangeGetVO.arrangeDataList">
                {{
                  item.bmsAttendanceArrangeGetVO.name +
                  "(" +
                  formatTime(item.bmsAttendanceArrangeGetVO.arrangeDataList) +
                  ")"
                }}
              </div>
            </div>
            <i class="el-icon-edit" style="cursor: pointer"></i>
          </div>
        </div>
        <div class="sch_title">
          <span class="sch_title_label">排班周期</span>
          <span
            v-if="this.selectedcycleSelection.length == 0"
            @click="editCycle"
            style="cursor: pointer"
          >
            <i class="el-icon-edit"></i>
            <span style="margin-left: 8px; color: #9a9a9a">配置周期</span>
          </span>
          <div v-else class="sch_title_d" @click="editCycle">
            <div
              v-for="item in selectedcycleSelection"
              class="sch_title_span"
              :key="item.id"
            >
              <div>{{ item.name }}</div>
            </div>
            <i class="el-icon-edit" style="cursor: pointer"></i>
          </div>
        </div>
        <div class="sch_search">
          <div class="sch_search_left">
            <div class="sch_search_item">
              <el-date-picker
                type="month"
                v-model="timevalue"
                range-separator="-"
                placeholder="选择日期"
                value-format="yyyy-MM"
                @change="onSearch"
                :clearable="false"
              ></el-date-picker>
            </div>
            <div class="sch_search_item">
              <div class="approval_search">
                <el-input
                  v-model="seaName"
                  @keyup.enter.native="searchName"
                  placeholder="搜索员工姓名"
                  class="department_search_inp"
                  style="width: 200px"
                ></el-input>

                <img
                  src="@/assets/structure/search.png"
                  class="searchicon"
                  @click="searchName"
                />
              </div>
            </div>
          </div>
          <div class="sch_search_right" @click="copyScheduling">复制排班至</div>
        </div>
        <div class="schedule_table">
          <table
            cellpadding="0"
            cellspacing="0"
            class="sch_table"
            ref="bigtable"
            :key="tableKey"
          >
            <tr v-for="(item, index) in ldata" :key="index">
              <template v-for="(items, index2) in item">
                <td class="sch_table_cell" :key="index2">
                  <div v-if="index != 0 && index2 != 0" class="bottom-popover">
                    <div
                      class="specia_not"
                      v-if="
                        index != 0 &&
                        index2 > today &&
                        items != '' &&
                        items.name != ''
                      "
                    >
                      <el-tooltip placement="top" popper-class="top_popover">
                        <div slot="content">
                          <div
                            class="sc_popover_top"
                            v-if="items.arrange == -1"
                          >
                            {{ items.name == "" ? "" : "休息" }}
                          </div>
                          <div
                            class="sc_popover_top"
                            v-else-if="items.arrange == -3"
                          >
                            {{ items.name == "" ? "" : "节假日" }}
                          </div>
                          <div
                            class="sc_popover_top"
                            v-else-if="items.bmsAttendanceArrangeGetVO"
                          >
                            {{ fomatName(items) }}
                          </div>

                          <div
                            v-if="items.arrange != -1 && items.arrange != -4"
                            class="sc_popover_top"
                          >
                            {{ formatTime2(items) }}
                          </div>
                        </div>
                        <el-button
                          v-show="index != 0 && index2 != 0"
                          @click="chooseTime(index, index2, items)"
                          :class="formatColor(items, index2)"
                          :disabled="isDisable(index2)"
                          :ref="index + `bt` + index2"
                        >
                          <span v-if="items.bmsAttendanceArrangeGetVO">
                            <div>{{ fomatName2(items) }}</div>
                            {{ fomatName3(items) }}
                          </span>

                          <span v-else> {{ items.name }}</span>
                          <div
                            v-if="items != '' && items.isred"
                            class="sch_box"
                          ></div>
                        </el-button>
                      </el-tooltip>
                    </div>
                    <div v-else class="specia_not">
                      <el-tooltip
                        v-if="isDisable(index2)"
                        class="item"
                        effect="dark"
                        content="不能修改当天以前的排期"
                        placement="top"
                      >
                        <!-- span是button的外层标签 -->
                        <span class="specia_not2">
                          <el-button
                            :class="formatColor(items, index2)"
                            :disabled="isDisable(index2)"
                            @click="chooseTime(index, index2, items)"
                            :ref="index + `bt` + index2"
                          >
                            <span v-if="items.bmsAttendanceArrangeGetVO">
                              <div>{{ fomatName2(items) }}</div>
                              {{ fomatName3(items) }}
                            </span>

                            <span v-else> {{ items.name }}</span>
                            <div
                              v-if="items != '' && items.isred"
                              class="sch_box"
                            ></div>
                          </el-button>
                        </span>
                      </el-tooltip>
                      <el-tooltip
                        placement="top"
                        v-else
                        popper-class="top_popover"
                      >
                        <div slot="content">
                          <div
                            class="sc_popover_top"
                            v-if="items.arrange == -1"
                          >
                            {{ items.name == "" ? "" : "休息" }}
                          </div>
                          <div
                            class="sc_popover_top"
                            v-else-if="items.arrange == -3"
                          >
                            {{ items.name == "" ? "" : "节假日" }}
                          </div>
                          <div
                            class="sc_popover_top"
                            v-else-if="items.bmsAttendanceArrangeGetVO"
                          >
                            {{ fomatName(items) }}
                          </div>

                          <div
                            v-if="items.arrange != -1 && items.arrange != -4"
                            class="sc_popover_top"
                          >
                            {{ formatTime2(items) }}
                          </div>
                        </div>
                        <el-button
                          :class="formatColor(items, index2)"
                          :disabled="isDisable(index2)"
                          @click="chooseTime(index, index2, items)"
                          :ref="index + `bt` + index2"
                        >
                          <span v-if="items.bmsAttendanceArrangeGetVO">
                            <div>{{ fomatName2(items) }}</div>
                            {{ fomatName3(items) }}
                          </span>

                          <span v-else> {{ items.name }}</span>
                          <div
                            v-if="items != '' && items.isred"
                            class="sch_box"
                          ></div>
                        </el-button>
                      </el-tooltip>
                    </div>
                    <!-- <el-popover popper-class="top_popover" v-if="index != 0 && index2 > today && items != ''"
                      placement="top" trigger="hover" :ref="index + 'refpopover' + index2">
                      <div class="sc_popover_top" v-if="items.arrange == -1">{{ items.name == '' ? '' : '休息' }}</div>
                      <div class="sc_popover_top" v-else-if="items.attendanceArrangeGetVO">{{ fomatName(items) }}</div>

                      <div v-if="items.arrange != -1" class="sc_popover_top">{{
                        formatTime2(items) }}</div>
                    </el-popover> -->
                  </div>
                  <div
                    v-else-if="index != 0 && index2 == 0"
                    class="single_seletc"
                  >
                    <el-checkbox v-model="items.select">{{
                      items.name
                    }}</el-checkbox>
                  </div>
                  <div
                    v-else-if="index == 0 && index2 != 0"
                    class="specia_cell"
                  >
                    <div>{{ items.index }}</div>
                    <div>{{ items.day }}</div>
                  </div>
                  <div v-else class="specia_not">{{ items }}</div>
                </td>
              </template>
            </tr>
            <el-popover
              v-if="showPop"
              ref="pop"
              :reference="reference"
              placement="bottom"
              trigger="click"
              class="sc_popover"
              popper-class="bottom_popover"
              :popper-options="{
                boundariesElement: 'viewport',
                removeOnDestroy: true,
              }"
            >
              <el-tabs
                v-if="ifShow"
                v-model="activeName"
                @tab-click="handleClick"
                class="sc_pop_tab"
              >
                <el-tab-pane label="按班次排班" name="first" class="aaa">
                  <div
                    style="padding-top: 10px"
                    v-if="copyselectedClass.length > 0"
                  >
                    <div
                      v-for="(item, index3) in copyselectedClass"
                      class="sc_pop_tab_list"
                      :key="index3"
                    >
                      <div
                        v-if="item.bmsAttendanceArrangeGetVO.arrangeDataList"
                        class="sc_tab_right_item"
                        @click="chooseClass(cuIndex, cuIndex2, item)"
                      >
                        {{ item.bmsAttendanceArrangeGetVO.name }}
                      </div>
                      <div
                        v-else
                        class="sc_tab_right_item sc_tab_right_relax"
                        @click="chooseClass(cuIndex, cuIndex2, item)"
                      >
                        休息
                      </div>
                    </div>
                  </div>
                  <div
                    class="sc_pop_tab_bottom"
                    @click="cleanCell(cuIndex, cuIndex2, cuItem)"
                  >
                    <i
                      class="el-icon-remove-outline"
                      style="margin-right: 3px"
                    ></i>
                    <span>清除</span>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="按周期排班" name="second" class="aaa">
                  <div class="sc_pop_tab_list" style="padding-top: 10px">
                    <div class="sc_tab_right_tip">从该天起排班至月底</div>
                    <div
                      v-for="(item, index4) in selectedcycleSelection"
                      :key="index4"
                    >
                      <span
                        class="sc_tab_right_item"
                        @click="chooseCycle(cuIndex, cuIndex2, item)"
                      >
                        {{ item.name }}
                      </span>
                    </div>
                  </div>
                  <!-- <div class="sc_pop_tab_bottom2">

                  </div> -->
                </el-tab-pane>
              </el-tabs>
            </el-popover>
          </table>
        </div>
        <div class="newpagination">
          <div class="newpagination_text">共 {{ pages1.total }} 条数据</div>
          <el-pagination
            layout="prev, pager, next"
            :total="pages1.total"
            :current-page="pages1.pageNum"
            :page-size="10"
            @current-change="changePage"
          >
          </el-pagination>
        </div>
        <div class="schedule_table2">
          <table
            cellpadding="0"
            cellspacing="0"
            class="sch_table"
            ref="bigtable2"
            :key="tableKey2"
          >
            <tr v-for="(item, index) in statisticsData" :key="index">
              <template v-for="(items, index2) in item">
                <td class="sch_table_cell" :key="index2">
                  <div v-if="index != 0 && index2 != 0" class="bottom-popover">
                    <div class="sch_table_text" v-if="item[index2] != 0">
                      {{ item[index2] }}
                    </div>
                    <div v-else class="sch_table_text"></div>
                  </div>
                  <el-tooltip
                    v-else-if="index != 0 && index2 == 0"
                    class="item"
                    effect="dark"
                    :content="item[index2]"
                    placement="top"
                  >
                    <div class="sch_table_text2">
                      {{ item[index2] }}
                    </div>
                  </el-tooltip>
                  <div
                    v-else-if="index == 0 && index2 != 0"
                    class="specia_cell"
                  >
                    <div>{{ items.index }}</div>
                    <div>{{ items.day }}</div>
                  </div>
                  <div v-else class="specia_not">{{ items }}</div>
                </td>
              </template>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <!-- 考勤班次 -->
    <el-drawer
      size="35%"
      :visible.sync="dialogClasses"
      custom-class="drawer_cycle"
      :direction="direction"
      :showClose="false"
      :wrapperClosable="false"
      :key="cKey"
    >
      <div slot="title" class="drawer_title">
        <i
          class="el-icon-close"
          style="font-size: 24px"
          @click="closeDrawer"
        ></i>
        <span class="drawer_title_filter">考勤班次</span>

        <div class="drawer_confirm">
          <el-button
            class="drawer_confirm_f"
            style="background: none"
            @click="closeDrawer"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="updateClass"
            class="drawer_confirm_f"
            >确 认</el-button
          >
        </div>
      </div>
      <el-input
        placeholder="搜索班次名称"
        class="drawer_search"
        v-model="arrangeName"
        @keyup.enter.native="searchClassName"
      >
        <i slot="suffix" class="el-icon-search" @click="searchClassName"></i
      ></el-input>
      <el-table
        class="drawer_table"
        :data="classSelection"
        @selection-change="handleSelectionChange"
        :header-cell-style="tableCellSytle"
        ref="itemTable"
        @select="itemHandleSelectionChange"
        @select-all="selection_change"
      >
        <el-table-column
          type="selection"
          header-align="left"
          align="left"
          width="40"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="班次名称"
          width="140"
        ></el-table-column>
        <el-table-column label="考勤时间">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              :content="attendanceTime(scope.row.arrangeDataList).join(',')"
              placement="top"
            >
              <div class="time_list">
                <div
                  class="time_single"
                  v-for="(item, index) in attendanceTime(
                    scope.row.arrangeDataList
                  )"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>
    <eject
      v-if="cycleDialog"
      :isShow="cycleDialog"
      @closeDiglog="closeCycle"
      @openAdd="openAdd"
      @affirmDiglog="closeAddCycle"
      @deleteCycle2="deleteCycle2"
      :key="cyclekey"
      :groupId="groupId"
      :cList="cList"
    >
    </eject>

    <!-- 复制排班 -->
    <el-drawer
      :wrapperClosable="false"
      title=""
      :key="schedulingKey"
      :visible.sync="schedulingDialog"
      :with-header="false"
      size="25%"
    >
      <Schedule
        :people="selectschedulingArr"
        @close="schedulingDialog = false"
        @comfin="comfinCopy"
      />
    </el-drawer>
  </div>
</template>
  
<script>
import http from "../../utils/http";
import eject from "./cycleDrawer.vue";
import Schedule from "./diglog/scheduling.vue";
export default {
  props: {
    // 点击高亮当前行
    highlightCurrentRow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      highlightedCell: false, // 保存当前高亮单元格的索引
      tableKey: 0,
      tableKey2: Math.random(),
      cycleDialog: false, //配置周期
      addDialog: false, //新建周期
      isHoliday: false, //是否应用节假日
      cKey: 1,
      cycleVisible: false,
      data: [],
      defaultProps: {
        children: "subDepartment",
        label: "departmentName",
      },

      direction: "rtl",
      value: "",
      arrangeName: "", //搜索班次名称
      timevalue: "",
      dialogClasses: false, //配置周次弹窗
      pages1: {}, //分页
      multipleSelection: [],
      classSelection: [], //全部班次列表
      selectedClassSelection: [], //选中的班次列表信息
      clockData: [], //规定上下班时间范围
      totalSelection: [],
      indeterminate: false,
      copyselectedClass: [
        {
          bmsAttendanceArrangeGetVO: {
            id: -1,
            name: "休息",
          },
          arrange: -1,
        },
      ], //选中的班次列表信息
      cycleSelection: [], //全部的周期列表
      selectedcycleSelection: [], //选中的周期列表信息
      cyclekey: null,
      hasSelectList: [], //已经选择的班次列表id
      tableData: [],
      namelist: [],
      searchPass: false,
      seaName: "", //搜索员工名字

      ldata: [],
      ldataCopy: [], //最终提交的数据
      activeName: "",
      ifShow: false,
      classList: [], //按班次排班列表
      holidayList: [], //节假日列表
      finalHoliday: [], //最终的节假日列表
      dataColum: [],
      cycleObj: [],
      isBefore: false,
      today: 0,
      isToMon: false, //是否是当月
      disable: false,
      pickerOptions: {
        disabledDate: (time) => {
          return Date.now() - 3600 * 1000 * 24 > time.getTime();
        },
      },
      formOptions: [],
      groupId: null,
      cList: [],
      oldClassList: [], //旧的排班规则
      oldClassList2: [],
      namelist: [], //获取得人员列表
      oldAttendList: [], //考勤列表
      oldAttendListCopy: [], //旧的考勤列表(复制)
      statisticsData: [], //统计列表
      lastTimeData: [], //上一次操作的数组
      arrList: [],
      addFlag: false,
      reference: {},
      // 控制渲染条件 如果不用v-if会报错 具体可自行尝试
      showPop: false,
      // 保存当前激活的refrence id
      activeId: "",
      cuIndex: null,
      cuIndex2: null,
      cuItem: null,

      // 复制排班
      selectschedulingArr: [], //选中的排班数组
      savedScheduling: [], //保存的排班
      schedulingDialog: false,
      schedulingKey: Math.random(),
    };
  },
  components: { eject, Schedule },
  watch: {
    activeName: function (newVal, oldVal) {
      if (!newVal) {
        return;
      }
      this.ifShow = true;
      if (newVal != oldVal) {
        this.$nextTick(() => {
          this.$refs.pop.updatePopper();
        });
      }
    },
    // arrangeName: function (newVal, oldVal) {
    //   if (newVal == '') {

    //     this.getClass({})
    //   }

    // },
  },
  computed: {},

  created() {
    this.toMonth();
    this.groupId = this.$route.query.id;
    this.isHoliday = JSON.parse(this.$route.query.isHoliday);
    // 获取考勤信息
    this.getPeopleList({ pageNum: 1 });
    // 获取该考勤组班次信息
    this.getClass({});
    // 获取考勤组配置
    this.groupSetting(this.$route.query.id);

    this.getCycle();

    // 获取节假日列表
    this.bmsHoliday();
  },
  mounted() {
    setTimeout(() => {
      this.ifShow = true;
    }, 10);
  },
  methods: {
    chooseClass(a, b, item) {
      let _this = this;
      let day = "";
      b < 10
        ? (day = this.timevalue + "-0" + b)
        : (day = this.timevalue + "-" + b);
      let newObj = {};
      if (this.ldata[a][b].oldNum) {
        newObj = { oldNum: 1, id: this.ldata[a][b].id };
      }

      if (b < 10) {
        newObj = {
          ...newObj,
          user: this.ldata[a][0].id,
          dayString: this.timevalue + "-0" + b,
          arrange: item.bmsAttendanceArrangeGetVO.id,
        };
      } else {
        newObj = {
          ...newObj,
          user: this.ldata[a][0].id,
          dayString: this.timevalue + "-" + b,
          arrange: item.bmsAttendanceArrangeGetVO.id,
        };
      }
      let citem = _this.oldAttendList.filter((item2) => {
        if (
          item2.arrange == item.bmsAttendanceArrangeGetVO.id &&
          item2.user == this.ldata[a][0].id &&
          day == item2.dayString
        )
          return true;
      });
      if (citem.length > 0) {
        this.$set(this.ldata[a], b, { ...item, ...newObj });
        // this.ldata[a][b] = { ...item, ...newObj };
      } else {
        this.$set(this.ldata[a], b, { ...item, ...newObj, isred: 1 });
        // this.ldata[a][b] = { ...item, ...newObj, isred: 1 };
      }
      this.tableKey = Math.random();

      var secondElements = this.ldata.map((subArray) => subArray[b]);

      var arr = [];
      if (secondElements.length > 0) {
        secondElements.map((item) => {
          if (item.bmsAttendanceArrangeGetVO) {
            arr.push(item.bmsAttendanceArrangeGetVO.name);
          } else {
            if (item.arrange == -1) {
              arr.push("休息");
            }
          }
        });
      }

      for (let index = 1; index < this.statisticsData.length; index++) {
        this.statisticsData[index][b] = 0;
        this.statisticsData[index].map((cietm) => {
          var isinclude = arr.includes(cietm);
          if (isinclude) {
            var count = arr.filter((element) => element === cietm).length;
            this.statisticsData[index][b] = count;
          }
        });
      }
    },
    chooseCycle(a, b, arr) {
      let len = this.ldata[a].length;
      for (
        let j = b;
        j < len;
        j = j + arr.bmsAttendanceArrangeGetVOList.length
      ) {
        this.chooseCycle2(a, j, arr);
        this.tableKey = Math.random();
      }
      // console.log(this.ldata)
    },
    chooseCycle2(a, b, arr) {
      let _this = this;
      let len = this.ldata[a].length;
      arr.bmsAttendanceArrangeGetVOList.map((element, i) => {
        let newObj = {};

        if (b + i + 1 > len) return;
        let day = "";
        b < 10
          ? (day = this.timevalue + "-0" + (b + i))
          : (day = this.timevalue + "-" + (b + i));
        if (this.ldata[a][b + i].oldNum) {
          newObj = { oldNum: 1, id: this.ldata[a][b + i].id };
        }
        if (b + i < 10) {
          newObj = {
            ...newObj,
            user: this.ldata[a][0].id,
            dayString: this.timevalue + "-0" + (b + i),
          };
        } else {
          newObj = {
            ...newObj,
            user: this.ldata[a][0].id,
            dayString: this.timevalue + "-" + (b + i),
          };
        }
        if (element.id) {
          newObj = {
            ...newObj,
            bmsAttendanceArrangeGetVO: element,
            arrange: element.id,
          };
        } else {
          newObj = {
            ...newObj,
            bmsAttendanceArrangeGetVO: {
              id: -1,
              name: "休息",
            },
            arrange: -1,
          };
        }

        if (element.id) {
          let citem = _this.oldAttendList.filter((item2) => {
            if (
              item2.arrange == element.id &&
              item2.user == this.ldata[a][0].id &&
              day == item2.dayString
            )
              return true;
          });
          if (citem.length > 0) {
            this.ldata[a][b + i] = { ...newObj };
            this.tableKey = Math.random();
            return;
          }
        } else {
          let citem = _this.oldAttendList.filter((item2) => {
            if (
              item2.arrange == -1 &&
              item2.user == this.ldata[a][0].id &&
              day == item2.dayString
            )
              return true;
          });
          if (citem.length > 0) {
            this.ldata[a][b + i] = { ...newObj };
            this.tableKey = Math.random();
            return;
          }
        }
        // this.statisticsData.map((value, index) => {
        //   if (element.id) {
        //     var isinclude = value.includes(element.name);
        //     if (isinclude) {
        //       value[b + i] += 1;
        //     }
        //   } else {
        //     var isinclude = value.includes("休息");
        //     if (isinclude) {
        //       value[b + i] += 1;
        //     }
        //   }
        // });
        this.ldata[a][b + i] = { ...newObj, isred: 1, cId: arr.id };

        var secondElements = this.ldata.map((subArray) => subArray[b + i]);
        var newArr = [];
        if (secondElements.length > 0) {
          secondElements.map((item) => {
            if (item.bmsAttendanceArrangeGetVO) {
              newArr.push(item.bmsAttendanceArrangeGetVO.name);
            }
          });
        }

        for (let index = 1; index < this.statisticsData.length; index++) {
          this.statisticsData[index][b + i] = 0;
          this.statisticsData[index].map((cietm) => {
            var isinclude = newArr.includes(cietm);
            if (isinclude) {
              var count = newArr.filter((element) => element === cietm).length;
              this.statisticsData[index][b + i] = count;
            }
          });
        }
      });
    },
    chooseTime(a, b, item) {
      this.ifShow = false;
      this.cuItem = item;
      // console.log(this.activeId)
      // 这个操作是为了避免与源码中的点击reference doToggle方法冲突
      // if (this.activeId === item && this.showPop) return
      this.showPop = false;
      this.activeId = item;
      // 因为reference是需要获取dom的引用 所以需要是$el

      this.reference = this.$refs[a + "bt" + b][0].$el;
      this.$nextTick(() => {
        // 等待显示的popover销毁后再 重新渲染新的popover
        this.showPop = true;
        this.$nextTick(() => {
          // 此时才能获取refs引用
          this.$refs.pop.doShow();
          // this.$refs.[a + 'bt' + b].updatePopper();

          this.ifShow = true;
          this.activeName = "first";
        });
      });
      this.cuIndex = a;
      this.cuIndex2 = b;
    },

    cleanCell(a, b, item) {
      if (item == "") return;
      if (item.oldNum) {
        this.ldata[a].splice(b, 1, {
          isred: 1,
          name: "",
          user: item.user,
          dayString: item.dayString,
          arrange: item.arrange,
          oldNum: 1,
          id: this.ldata[a][b].id,
          isclean: 1,
        });
      } else this.ldata[a].splice(b, 1, "");
      this.$refs.pop.doClose();

      this.statisticsData.map((value, index) => {
        var isinclude = value.includes(item.bmsAttendanceArrangeGetVO.name);
        if (isinclude) {
          value[b] -= 1;
        }
      });
    },
    handleClick(tab, event) {
      // this.$refs.pop.updatePopper()
    },

    // 获取考勤组配置(最早最晚下班时间)
    groupSetting(id) {
      http
        .get("/bmsAttendanceGroup/get", {
          id: id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.clockData = res.data.attendanceGroupConfigData.clockData;
          }
        });
    },

    // 复制排班
    copyScheduling() {
      var _this = this;
      var newArr = [];
      var arr = [];
      for (let index = 0; index < _this.ldata.length; index++) {
        var object = _this.ldata[index][0];
        if (_this.isObject(object)) {
          if (object.select) {
            arr.push(object.id);
          }
        }
      }
      if (arr.length == 0 || arr.length > 1) {
        this.$message.error("请选择一个排班");
        return;
      }
      http
        .get("/bmsAttendanceGroup/get/people", {
          pageSize: 9999,
          id: this.groupId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.pages1 = res.data;
            var arrC = res.data.list;
            if (arr.length > 0) {
              this.ldataCopy.splice(1);
              arrC.map((item) => {
                let arr2 = new Array(this.ldataCopy[0].length).fill("");
                arr2[0] = { id: item.id, name: item.name, select: false };
                this.ldataCopy.push(arr2);
              });

              this.isBeforeDay();
            } else {
              this.ldataCopy.splice(1);
            }
            // 生成最终的人物选择
            var id = arr[0];
            var object = JSON.stringify(arrC);
            object = JSON.parse(object);
            this.selectschedulingArr = object;

            // 保存需要复制的排班
            for (let index = 0; index < _this.ldata.length; index++) {
              var object = _this.ldata[index][0];
              if (_this.isObject(object)) {
                if (object.id == id) {
                  newArr = JSON.stringify(_this.ldata[index]);
                }
              }
            }
            newArr = JSON.parse(newArr);
            newArr.map((item) => {
              if (item.id) {
                item.isred = 1;
              }
            });

            newArr.shift();
            _this.savedScheduling = newArr;
            this.getListCopy(arrC, this.ldataCopy);
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 判断是不是对象
    isObject(value) {
      return typeof value === "object" && value !== null;
    },
    // 确认复制排班
    comfinCopy(value) {
      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();
      var currentMonth =
        currentDate.getMonth() + 1 < 10
          ? "0" + (currentDate.getMonth() + 1)
          : currentDate.getMonth() + 1;
      var currentDay = currentDate.getDate();
      var nowDate = currentYear + "-" + currentMonth;
      var _this = this;
      for (let index = 0; index < _this.ldataCopy.length; index++) {
        var object = _this.ldataCopy[index][0];
        if (object.id) {
          var ispackage = value.includes(object.id);
          if (ispackage) {
            // 当月以后
            if (this.timevalue > nowDate) {
              _this.ldataCopy[index].splice(
                1,
                _this.savedScheduling.length,
                ..._this.savedScheduling
              );
            } else if (this.timevalue == nowDate) {
              var newArr = JSON.stringify(_this.savedScheduling);
              newArr = JSON.parse(newArr);
              var lastArr = newArr.splice(currentDay, newArr.length);
              _this.ldataCopy[index].splice(
                currentDay + 1,
                lastArr.length,
                ...lastArr
              );
            }
          }
        }
      }

      var arr = JSON.stringify(_this.ldataCopy);
      arr = JSON.parse(arr);
      for (let index = 1; index < arr.length; index++) {
        var obj = arr[index][0];
        for (let i = 0; i < arr[index].length; i++) {
          if (arr[index][i].user) {
            arr[index][i].user = obj.id;
          }
        }
      }

      this.confirmSchedule2(arr);
    },
    // 选择复选框
    change(value, index) {
      if (value) {
        this.$set(this.ldata[index][0], "select", true);
      } else {
        this.$set(this.ldata[index][0], "select", false);
      }
    },

    // 获取节假日列表
    bmsHoliday() {
      http
        .get("/bmsHoliday/list", {
          pageNum: 1,
          pageSize: 999,
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.list.length > 0) {
              var arr = this.getAllDates(res.data.list);
              this.holidayList = arr;
            }
          }
        });
    },

    // 处理节假日日期
    getAllDates(ranges) {
      // 将日期字符串解析为 Date 对象
      const parseDate = (dateString) => new Date(dateString);

      // 生成从开始日期到结束日期的所有日期
      const getDatesInRange = (startDate, endDate) => {
        let dates = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
          dates.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
      };

      // 存储所有日期的数组
      let allDates = [];

      ranges.forEach((range) => {
        let startDate = parseDate(range.dayBegin);
        let endDate = parseDate(range.dayEnd);
        let datesInRange = getDatesInRange(startDate, endDate);
        allDates = allDates.concat(datesInRange);
      });

      // 格式化日期为 YYYY-MM-DD
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始计数，需要加1，并保证两位数
        const day = String(date.getDate()).padStart(2, "0"); // 获取日期，并保证两位数
        return `${year}-${month}-${day}`;
      };

      return allDates.map(formatDate);
    },

    // 保存排班信息
    confirmSchedule() {
      const loading = this.$loading({
        lock: true,
        text: "保存中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let _this = this;
      let arr = _this.ldata;
      arr = arr.filter((item) => {
        return item != "";
      });

      let updateList = []; //更新的列表
      let addList = []; //增加的列表
      let deList = []; //删除的列表

      for (let i = 1; i < arr.length; i++) {
        arr[i].map((item, inx) => {
          if (item != "" && inx > 0) {
            if (item.isred) {
              if (item.name !== "") {
                addList.push(item);
                _this.oldAttendList.map((item2, inx2) => {
                  // 判断是否不变动
                  if (
                    parseInt(item.user) == item2.user &&
                    item.dayString == item2.dayString
                  ) {
                    addList.pop(item);
                  }

                  if (
                    parseInt(item.user) == item2.user &&
                    item.dayString == item2.dayString &&
                    item.arrange != item2.arrange
                  ) {
                    updateList.push({
                      id: item2.id,
                      arrange: item.arrange,
                      user: item.user,
                      dayString: item.dayString,
                    });
                  }
                });
              } else {
                deList.push(item);
              }
            }
          }
        });
      }
      // 删除的数据
      let newObj = {};
      if (deList.length > 0) {
        let arr4 = [];
        deList.map((item) => {
          var index = this.finalHoliday.indexOf(item.dayString);
          if (this.isHoliday) {
            if (index == -1) {
              arr4.push(item.id);
            }
          } else {
            arr4.push(item.id);
          }
        });
        newObj.deleteAttendanceRecord = arr4;
      }
      if (addList.length > 0) {
        var extraItems = addList.filter(
          (item1) =>
            !updateList.some(
              (item2) => item2.id === item1.id && item2.user === item1.user
            )
        );
        let alist = [];
        extraItems.map((item) => {
          var index = this.finalHoliday.indexOf(item.dayString);
          if (this.isHoliday) {
            if (index == -1) {
              alist.push({
                user: item.user,
                dayString: item.dayString,
                arrange: item.arrange,
              });
            }
          } else {
            alist.push({
              user: item.user,
              dayString: item.dayString,
              arrange: item.arrange,
            });
          }
        });
        newObj.attendanceRecordAddDataList = alist;
      }
      if (updateList.length > 0) {
        updateList.map((item) => {
          delete item.user;
        });
        newObj.attendanceRecordUpdateDataList = updateList;
      }

      if (addList.length == 0 && updateList.length == 0 && deList.length == 0) {
        this.$message.success("保存成功,考勤规则将于明日生效");
        this.onSearch();
        return;
      }

      // 节假日一律作做处理
      var value = JSON.stringify(newObj);
      value = JSON.parse(value);

      http
        .post("/bmsAttendanceRecord/update", {
          ...value,
          attendanceGroup: this.groupId,
        })
        .then((res) => {
          loading.close();
          if (res.code == 200) {
            this.$message.success("保存成功");
            this.onSearch();
          } else {
            this.$message.error(res.message);
          }
        });
    },

    // 保存排班信息
    confirmSchedule2(finalArr) {
      const loading = this.$loading({
        lock: true,
        text: "保存中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let _this = this;
      let arr = finalArr;
      arr = arr.filter((item) => {
        return item != "";
      });

      let updateList = []; //更新的列表
      let addList = []; //增加的列表
      let deList = []; //删除的列表

      for (let i = 1; i < arr.length; i++) {
        arr[i].map((item, inx) => {
          if (item != "" && inx > 0) {
            if (item.isred) {
              if (item.name !== "") {
                addList.push(item);
                _this.oldAttendListCopy.map((item2, inx2) => {
                  // 判断是否不变动
                  if (
                    parseInt(item.user) == item2.user &&
                    item.dayString == item2.dayString
                  ) {
                    addList.pop(item);
                  }

                  if (
                    parseInt(item.user) == item2.user &&
                    item.dayString == item2.dayString &&
                    item.arrange != item2.arrange
                  ) {
                    updateList.push({
                      id: item2.id,
                      arrange: item.arrange,
                      user: item.user,
                      dayString: item.dayString,
                    });
                  }
                });
              } else {
                deList.push(item);
              }
            }
          } else {
            var dString = "";
            var id = arr[i][0].id;
            if (inx < 10) {
              dString = "0" + inx;
            } else {
              dString = inx;
            }
            var string = this.timevalue + "-" + dString;
            _this.oldAttendListCopy.map((item) => {
              if (item.user == id && item.dayString == string) {
                deList.push({
                  id: item.id,
                });
              }
            });
          }
        });
      }

      // 删除的数据
      let newObj = {};
      if (deList.length > 0) {
        let arr4 = [];
        deList.map((item) => {
          var index = this.finalHoliday.indexOf(item.dayString);
          if (this.isHoliday) {
            if (index == -1) {
              arr4.push(item.id);
            }
          } else {
            arr4.push(item.id);
          }
        });
        newObj.deleteAttendanceRecord = arr4;
      }
      if (addList.length > 0) {
        var extraItems = addList.filter(
          (item1) =>
            !updateList.some(
              (item2) => item2.id === item1.id && item2.user === item1.user
            )
        );
        let alist = [];
        extraItems.map((item) => {
          var index = this.finalHoliday.indexOf(item.dayString);
          if (this.isHoliday) {
            if (index == -1) {
              alist.push({
                user: item.user,
                dayString: item.dayString,
                arrange: item.arrange,
              });
            }
          } else {
            alist.push({
              user: item.user,
              dayString: item.dayString,
              arrange: item.arrange,
            });
          }
        });
        newObj.attendanceRecordAddDataList = alist;
      }
      if (updateList.length > 0) {
        updateList.map((item) => {
          delete item.user;
        });
        newObj.attendanceRecordUpdateDataList = updateList;
      }

      if (addList.length == 0 && updateList.length == 0 && deList.length == 0) {
        this.$message.success("保存成功,考勤规则将于明日生效");
        this.onSearch();
        return;
      }

      // 节假日一律作做处理
      var value = JSON.stringify(newObj);
      value = JSON.parse(value);

      http
        .post("/bmsAttendanceRecord/update", {
          ...value,
          attendanceGroup: this.groupId,
        })
        .then((res) => {
          loading.close();
          if (res.code == 200) {
            this.$message.success("保存成功");
            this.onSearch();
            this.schedulingDialog = false;
          } else {
            this.$message.error(res.message);
          }
        });
    },

    // 考勤列表
    getList(onform) {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      var _this = this;
      if (_this.searchName != "") onform = { ...onform };
      let currTime = _this.timevalue;
      http
        .get("/bmsAttendanceRecord/list", {
          ...onform,
          pageNum: 1,
          pageSize: 20000,
          attendanceGroup: this.groupId,
          monthString: this.timevalue,
        })
        .then((res) => {
          loading.close();
          if (res.code == 200) {
            let arr = res.data.list;
            let arr4 = _this.ldata;
            // 筛选2024年7月的数据
            var dateArr = currTime.split("-");
            const julyDates = this.holidayList.filter((dateStr) => {
              const date = new Date(dateStr);
              return (
                date.getFullYear() === parseInt(dateArr[0]) &&
                date.getMonth() + 1 === parseInt(dateArr[1])
              ); // 注意：getMonth()返回的是0-11
            });

            this.finalHoliday = julyDates;
            arr.map((item) => {
              _this.namelist.map((item2, indx) => {
                if (item.id == item2.id) {
                  let arr2 = item.attendanceRecordListDataList;
                  arr2.map((item3) => {
                    // console.log(item3.attendanceArrangeGetVO)
                    // if (item3.attendanceArrangeGetVO !== undefined) {
                    let day = item3.dayString;
                    // if (day.substring(0, 7) == currTime) {
                    let obj = {
                      id: item3.id,
                      bmsAttendanceArrangeGetVO:
                        item3.bmsAttendanceArrangeGetVO,
                      arrange: item3.arrange, //班次标识

                      dayString: day,
                      user: item.id,
                      oldNum: 1,
                    };
                    if (item3.arrange == -1 || item3.arrange == -2)
                      obj = { ...obj, name: "休息" };
                    else if (item3.arrange == -3) {
                      obj = { ...obj, name: "节假日" };
                    } else {
                      obj = {
                        ...obj,
                        name: item3.bmsAttendanceArrangeGetVO.name,
                      };
                    }

                    if (day.substring(8, 9) == 0)
                      arr4[indx + 1][day.substring(9)] = obj;
                    else arr4[indx + 1][day.substring(8)] = obj;
                    // }
                    // 旧的排班

                    _this.oldAttendList.push(obj);
                    // }
                  });
                }
              });
            });
            if (this.isHoliday) {
              for (let index = 1; index < arr4.length; index++) {
                for (let i = 0; i < arr4[index].length; i++) {
                  var isexist = this.isDayInArray(julyDates, i);
                  if (isexist) {
                    arr4[index][i] = {
                      dayString: currTime + "-" + (i < 10 ? "0" + i : i),
                      arrange: -3,
                      name: "节假日",
                    };
                  }
                }
              }
            }
            // 先循环清楚
            for (let index = 1; index < this.statisticsData.length; index++) {
              for (let d = 1; d < this.statisticsData[index].length; d++) {
                this.statisticsData[index][d] = 0;
              }
            }

            // 再算相加
            this.ldata = arr4;
            for (let index = 1; index < arr4.length; index++) {
              for (let cindex = 0; cindex < arr4[index].length; cindex++) {
                if (arr4[index][cindex].dayString) {
                  this.statisticsData.map((value) => {
                    var isinclude = value.includes(arr4[index][cindex].name);
                    if (isinclude) {
                      value[cindex] += 1;
                    }
                  });
                }
              }
            }

            this.tableKey = Math.random();
            this.tableKey2 = Math.random();
          } else {
            this.$message.error(res.message);
          }
        });
    },

    // 查询所有的考勤列表
    getListCopy(people, cobearr) {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var _this = this;
      let currTime = _this.timevalue;
      http
        .get("/bmsAttendanceRecord/list", {
          pageNum: 1,
          pageSize: 20000,
          attendanceGroup: this.groupId,
          monthString: this.timevalue,
        })
        .then((res) => {
          loading.close();
          if (res.code == 200) {
            let arr = res.data.list;
            let arr4 = cobearr;
            // 筛选2024年7月的数据
            var dateArr = currTime.split("-");
            const julyDates = this.holidayList.filter((dateStr) => {
              const date = new Date(dateStr);
              return (
                date.getFullYear() === parseInt(dateArr[0]) &&
                date.getMonth() + 1 === parseInt(dateArr[1])
              ); // 注意：getMonth()返回的是0-11
            });
            this.finalHoliday = julyDates;
            arr.map((item) => {
              people.map((item2, indx) => {
                if (item.id == item2.id) {
                  let arr2 = item.attendanceRecordListDataList;
                  arr2.map((item3) => {
                    // console.log(item3.attendanceArrangeGetVO)
                    // if (item3.attendanceArrangeGetVO !== undefined) {
                    let day = item3.dayString;
                    // if (day.substring(0, 7) == currTime) {
                    let obj = {
                      id: item3.id,
                      bmsAttendanceArrangeGetVO:
                        item3.bmsAttendanceArrangeGetVO,
                      arrange: item3.arrange, //班次标识

                      dayString: day,
                      user: item.id,
                      oldNum: 1,
                    };
                    if (item3.arrange == -1 || item3.arrange == -2)
                      obj = { ...obj, name: "休息" };
                    else if (item3.arrange == -3) {
                      obj = { ...obj, name: "节假日" };
                    } else {
                      obj = {
                        ...obj,
                        name: item3.bmsAttendanceArrangeGetVO.name,
                      };
                    }

                    if (day.substring(8, 9) == 0)
                      arr4[indx + 1][day.substring(9)] = obj;
                    else arr4[indx + 1][day.substring(8)] = obj;
                    // }
                    // 旧的排班

                    _this.oldAttendListCopy.push(obj);
                    // }
                  });
                }
              });
            });
            if (this.isHoliday) {
              for (let index = 1; index < arr4.length; index++) {
                for (let i = 0; i < arr4[index].length; i++) {
                  var isexist = this.isDayInArray(julyDates, i);
                  if (isexist) {
                    arr4[index][i] = {
                      dayString: currTime + "-" + (i < 10 ? "0" + i : i),
                      arrange: -3,
                      name: "节假日",
                    };
                  }
                }
              }
            }

            // 再算相加
            setTimeout(() => {
              _this.schedulingDialog = true;
            }, 200);
          } else {
            this.$message.error(res.message);
          }
        });
    },

    // 查询是否有日
    isDayInArray(arr, num) {
      for (var i = 0; i < arr.length; i++) {
        var dateParts = arr[i].split("-"); // 分割日期字符串为数组 [年, 月, 日]
        var day = parseInt(dateParts[2], 10); // 提取“日”部分并转换为整数
        if (day === num) {
          return true; // 如果找到匹配的“日”，返回 true
        }
      }
      return false; // 如果没有找到匹配的“日”，返回 false
    },
    onSearch(num) {
      this.ldata = [];
      const arr = this.timevalue.split("-");
      const days = this.getDaysInMonth(arr[0], arr[1]);
      let array = new Array(days + 1).fill("");
      let arr2 = new Array(days + 1).fill("");

      // 获取统计列表的第一行
      var statisticsDataArr = this.statisticsData[0];
      // 获取长度
      var length = array.length;

      if (statisticsDataArr.length > length) {
        var diff = length - statisticsDataArr.length;
        statisticsDataArr.splice(diff);
      }
      if (statisticsDataArr.length < length) {
        var diff = length - statisticsDataArr.length;
        for (let a = 0; a < diff; a++) {
          statisticsDataArr.push({});
        }
      }


      array[0] = "姓名";
      statisticsDataArr[0] = "班次";
      for (let i = 1; i < length; i++) {
        var date = new Date(arr[0] + "-" + arr[1] + "-" + i);
        const dayArr = ["日", "一", "二", "三", "四", "五", "六"];
        var dayOfWeek = dayArr[date.getDay()];
        array[i] = {
          index: i,
          day: dayOfWeek,
        };
        var object = {
          index: i,
          day: dayOfWeek,
        };
        statisticsDataArr[i] = object;
      }

      // 循环删除
      for (let i = 1; i < this.statisticsData.length; i++) {
        var length = this.statisticsData[0].length;
        var alength = this.statisticsData[i].length;
        if (alength > length) {
          var diff = length - alength;
          this.statisticsData[i].splice(diff);
        }
        if (alength < length) {
          var diff = length - alength;
          for (let a = 0; a < diff; a++) {
            this.statisticsData[i].push(0);
          }
        }
        for (let index = 1; index < this.statisticsData[i].length; index++) {
          this.statisticsData[i][index] = 0;
        }
      }

      this.ldata.push(array);
      this.namelist.map((item, index) => {
        let arr = new Array(days + 1).fill("");
        arr[0] = { id: item.id, name: item.name, select: false };
        this.ldata.push(arr);
      });

      this.isBeforeDay();
      // this.searchName();
      if (this.seaName != "") this.getList({ name: this.seaName });
      else this.getList({});
    },
    searchName() {
      if (this.seaName == "") {
        this.getPeopleList({ pageNum: 1 });
      } else this.getPeopleList({ pageNum: 1, name: this.seaName });
    },
    clearup() {
      this.passform.nameAndSNLike = "";
      this.getList({
        pageNum: 1,
      });
    },

    handleCheckChange() {},
    closeDrawer() {
      this.dialogClasses = false;
      this.cKey = Math.random();
    },

    addschedule() {
      this.$router.replace({ path: "/addschedule" });
    },
    schedule() {
      this.$router.replace({ path: "/schedule" });
    },
    itemHandleSelectionChange(selection, row) {
      // this.multipleSelection = selection
      let selected = selection.length && selection.indexOf(row) !== -1;
      this.totalSelection.map((item) => {
        if (item.id == row.id) item.ischecked = selected;
      });
    },
    selection_change(selection) {
      if (selection.length == 0) {
        this.indeterminate = true;
        this.classSelection.map((item) => {
          // item2.ischecked = false
          this.totalSelection.map((item2) => {
            if (item.id == item2.id) item2.ischecked = false;
          });
        });
      } else {
        this.indeterminate = false;
        selection.map((item) => {
          this.totalSelection.map((item2) => {
            if (item.id == item2.id) item2.ischecked = true;
          });
        });
      }
    },
    handleSelectionChange(val) {},
    // 获取考勤班次
    getClass(obj) {
      http
        .get(
          "/bmsAttendanceGroupArrange/list",
          Object.assign(
            {
              attendanceGroup: this.groupId,
            },
            obj
          )
        )
        .then((res) => {
          if (res.code == 200) {
            if (!this.dialogClasses) {
              this.selectedClassSelection = [];
              this.totalSelection = [];
            }

            this.classSelection = [];
            this.copyselectedClass = [
              {
                bmsAttendanceArrangeGetVO: {
                  id: -1,
                  name: "休息",
                },
                arrange: -1,
              },
            ];
            if (res.data.length > 0) {
              res.data.map((item) => {
                item.bmsAttendanceArrangeGetVO.groupArrangeId =
                  item.groupArrangeId;
                if (item.choose) {
                  this.hasSelectList.push(item.groupArrangeId);
                  if (!this.dialogClasses) {
                    this.totalSelection.push({
                      ...item.bmsAttendanceArrangeGetVO,
                      ischecked: true,
                    });
                    this.selectedClassSelection.push(item);
                  }

                  this.copyselectedClass.push(item);
                  this.classSelection.push({
                    ...item.bmsAttendanceArrangeGetVO,
                    ischecked: true,
                  });
                } else {
                  this.classSelection.push({
                    ...item.bmsAttendanceArrangeGetVO,
                    ischecked: false,
                  });
                  if (!this.dialogClasses) {
                    this.totalSelection.push({
                      ...item.bmsAttendanceArrangeGetVO,
                      ischecked: false,
                    });
                  }
                }
              });
            }
            if (this.dialogClasses) {
              var arr = [];

              this.totalSelection.map((item) => {
                if (item.ischecked) {
                  this.classSelection.map((item2) => {
                    if (item.id == item2.id) arr.push(item2);
                  });
                }
              });
              arr.map((item) => {
                this.$nextTick(function () {
                  this.$refs.itemTable.toggleRowSelection(item, true);
                });
              });
            }

            // 处理统计的数组
            var newArr = [];
            this.statisticsData.map((item, dindex) => {
              var cvl = item.includes("休息");
              if (!cvl) {
                newArr.push(["休息"]);
              }
              for (
                let index = 0;
                index < this.selectedClassSelection.length;
                index++
              ) {
                var arr = [];
                var cindex = item.includes(
                  this.selectedClassSelection[index].bmsAttendanceArrangeGetVO
                    .name
                );
                if (!cindex) {
                  arr[0] =
                    this.selectedClassSelection[
                      index
                    ].bmsAttendanceArrangeGetVO.name;
                  newArr.push(arr);
                }
              }
            });
            var uniqueArray = Array.from(
              new Set(newArr.map(JSON.stringify)),
              JSON.parse
            );
            // 查找一共有几个天数
            var length = this.statisticsData[0].length;
            // 循环添加进入新的数组
            uniqueArray.map((item) => {
              for (let index = 0; index < length - 1; index++) {
                item.push(0);
              }
            });

            // 这是添加
            var handleaddArr = this.findDifferentArrays(
              uniqueArray,
              this.lastTimeData
            );
            // 这是删除
            var handledeleteArr = this.findDifferentArrays(
              this.lastTimeData,
              uniqueArray
            );

            handledeleteArr.map((item) => {
              this.statisticsData.map((citem, cindex) => {
                var isinclude = citem[0].includes(item[0]);
                if (isinclude) {
                  this.statisticsData.splice(cindex, 1);
                }
              });
            });

            handleaddArr.map((item) => {
              this.statisticsData.push(item);
            });

            // 先保存一下前一次的操作
            this.lastTimeData = uniqueArray;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 找不相同的两个二维数组
    findDifferentArrays(arr1, arr2) {
      const set1 = new Set(arr1.map(JSON.stringify));
      const set2 = new Set(arr2.map(JSON.stringify));

      // 使用 Set 对象的差集操作找到不同的子数组
      const difference = new Set([...set1].filter((x) => !set2.has(x)));

      // 将差集中的子数组转换回数组形式
      const result = [...difference].map(JSON.parse);

      return result;
    },

    // 更新考勤班次
    updateClass() {
      var _this = this;

      let newObj = { id: this.groupId };

      let flag = true;
      let arr = [];
      let grr = [];
      var newArr = [];
      _this.totalSelection.map((item) => {
        if (item.ischecked) {
          newArr.push(item);
          arr.push(item.id);
          if (item.groupArrangeId) grr.push(item.groupArrangeId);
          flag = false;
        }
      });

      if (flag) {
        this.$message.error("必须选择一个考勤班次");
        return;
      }

      // 判断选择的班次是不是在开始结束时间范围内
      newArr.map((item) => {
        item.begin = 0;
        item.end = 0;
        if (item.arrangeDataList) {
          item.begin = item.arrangeDataList[0].clockData.beginDaySecond;
          item.end =
            item.arrangeDataList[
              item.arrangeDataList.length - 1
            ].clockData.endDaySecond;
        }
      });

      var isDown = this.checkTimeRanges(
        newArr,
        this.clockData.beginDaySecond,
        this.clockData.endDaySecond
      );
      if (!isDown) {
        this.$message.error("班次不在每日开始结束范围内,请重新选择");
        return;
      }

      // 新增的班次列表
      let arr1 = _this.getAbsentValues(_this.oldClassList, arr);
      // 删除班次列表
      let arr2 = _this.getAbsentValues(arr, _this.oldClassList);
      let arr3 = _this.getAbsentValues(grr, _this.oldClassList2);

      // 排班周期有的班次不能删除
      let dArr = [];
      for (let i = 0; i < this.selectedcycleSelection.length; i++) {
        let classArr =
          this.selectedcycleSelection[i].bmsAttendanceArrangeGetVOList;
        dArr = classArr.filter((item) => {
          if (arr2.indexOf(item.id) > -1) {
            return item;
          }
        });
      }

      let dList = [];
      dArr.map((item) => {
        if (!dList.includes(item.name)) dList.push(item.name);
      });
      if (dList.length > 0) {
        _this.$message.error(
          "排班周期包含" + dList.join("、") + ",不能取消勾选"
        );
        return;
      }
      if (arr1.length <= 0 && arr2.length <= 0) {
        this.$message.success("修改成功");
        this.dialogClasses = false;
        this.cKey = Math.random();
        return;
      }
      if (arr1.length > 0) {
        newObj.addChooseArrange = arr1;
      }
      if (arr3.length > 0) newObj.deleteChooseGroupArrange = arr3;
      let arrList = _this.ldata;

      http.post("/bmsAttendanceGroupArrange/update", newObj).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          if (arr2.length > 0) _this.deleteClass(arrList, arr2);
          this.cKey = Math.random();
          this.getClass({});
          this.dialogClasses = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 判断固定排班是不是都在可允许的范围内
    checkTimeRanges(arr, start, dend) {
      // 遍历数组中的每个对象
      for (let i = 0; i < arr.length; i++) {
        const { begin, end } = arr[i];

        // 检查begin和end是否都在start和end之间
        if (begin < start || end > dend) {
          // 如果任何一个对象的begin或end不满足条件，则返回false
          return false;
        }
      }

      // 如果所有对象都满足条件，则返回true
      return true;
    },
    // 当班次删除时，已选的得重新选
    deleteClass(arrList, arr2) {
      for (let i = 1; i < arrList.length; i++) {
        for (let j = this.today + 1; j < arrList[i].length; j++) {
          if (arrList[i][j] !== "" && arrList[i][j].isred) {
            let item = arr2.filter((item2) => {
              if (arrList[i][j].bmsAttendanceArrangeGetVO)
                // console.log(arrList[i][j].attendanceArrangeGetVO.id)
                return item2 === arrList[i][j].bmsAttendanceArrangeGetVO.id;
            });
            if (item.length > 0) {
              this.cleanCell(i, j, arrList[i][j]);
            }
          }
        }
      }
    },
    // 获取考勤周期
    getCycle() {
      http
        .get("/bmsAttendanceWeek/list", {
          attendanceGroup: this.groupId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.selectedcycleSelection = [];
            if (res.data.list.length > 0) {
              this.cycleSelection = res.data.list;
              res.data.list.map((item) => {
                if (item.choose) {
                  this.selectedcycleSelection.push(item);
                }
              });
            }
          } else {
            this.$message.error(res.message);
          }
        });
    },
    tableCellSytle() {
      return "background-color: #F5F6FA; color: #5C5C5C;";
    },
    backGroup() {
      this.$router.replace({ path: "/group" });
    },

    getdeList(arr1, arr2) {
      let res = [];
      for (let i = 0; i < arr1.length; i++) {
        let isExit = false;
        let item1 = arr1[i];
        let itemName1 = arr1[i].user;
        let itemName3 = arr1[i].dayString;
        let itemName5 = arr1[i].arrange;
        for (let j = 0; j < arr2.length; j++) {
          let item2 = arr2[j];
          let itemName2 = parseInt(arr2[j].user);
          let itemName4 = arr2[j].dayString;
          let itemName6 = arr2[j].arrange;
          if (
            itemName1 == itemName2 &&
            itemName3 == itemName4 &&
            itemName5 == itemName6
          ) {
            isExit = true;
            break;
          }
        }

        if (isExit) {
          res.push(item1);
        }
      }
      return res;
    },
    cancelSchedule() {
      this.$router.replace({ path: "/group" });
    },
    editClasses() {
      this.dialogClasses = true;
      if (this.arrangeName != "") {
        this.arrangeName = "";
        this.getClass();
      }

      var arr = [];
      arr = this.classSelection.filter((item) =>
        this.hasSelectList.includes(item.groupArrangeId)
      );
      this.oldClassList = [];
      this.oldClassList2 = [];
      arr.map((item) => {
        this.oldClassList.push(item.id);
        this.oldClassList2.push(item.groupArrangeId);
        this.multipleSelection.push(item);
        var index = this.totalSelection.findIndex(
          (citem) => citem.id == item.id
        );
        if (index > -1) {
          this.totalSelection[index].ischecked = true;
        }
        this.$nextTick(function () {
          this.$refs.itemTable.toggleRowSelection(item, true);
        });
      });
    },
    // 获取人员列表
    getPeopleList(search) {
      http
        .get("/bmsAttendanceGroup/get/people", {
          ...search,
          pageSize: 10,
          id: this.groupId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.pages1 = res.data;
            this.namelist = res.data.list;
            if (this.namelist.length > 0) {
              this.ldata.splice(1);
              this.namelist.map((item) => {
                let arr2 = new Array(this.ldata[0].length).fill("");
                arr2[0] = { id: item.id, name: item.name, select: false };
                this.ldata.push(arr2);
              });

              this.isBeforeDay();
            } else {
              this.ldata.splice(1);
            }
            if (search) this.getList(search);
            else this.getList({});
          } else {
            this.$message.error(res.message);
          }
        });
    },

    // 考勤时间
    attendanceTime(arr) {
      var newArr = [];
      if (arr) {
        arr.map((item) => {
          var start = ""; //开始时间
          var end = ""; //结束时间
          if (item.clockData.beginDaySecond) {
            start = this.secondsToHourMin(item.clockData.beginDaySecond);
          }
          if (item.clockData.endDaySecond) {
            end = this.secondsToHourMin(item.clockData.endDaySecond);
          }
          newArr.push(start + "-" + end);
        });

        return newArr;
      } else {
        return newArr;
      }
    },

    formatTime2(aa) {
      if (aa.bmsAttendanceArrangeGetVO) {
        let tt = aa.bmsAttendanceArrangeGetVO.arrangeDataList;
        if (tt) {
          if (tt.length <= 0) return "";
          let str = "";
          for (let i = 0; i < tt.length; i++) {
            var value1 = this.secondsToHourMin(tt[i].clockData.beginDaySecond);
            var value2 = this.secondsToHourMin(tt[i].clockData.endDaySecond);
            let str2 = value1 + "-" + value2;
            if (str == "") str = str2;
            else str = str + "  " + str2;
          }
          return "考勤时间：" + str;
        }
      }
    },
    fomatName(nn) {
      if (nn.name == "") return "";
      else return nn.bmsAttendanceArrangeGetVO.name;
    },
    fomatName2(nn) {
      if (nn.bmsAttendanceArrangeGetVO.name)
        return nn.bmsAttendanceArrangeGetVO.name.substring(0, 2);
    },
    fomatName3(nn) {
      if (nn.bmsAttendanceArrangeGetVO.name)
        return nn.bmsAttendanceArrangeGetVO.name.substring(2, 4);
    },
    getClassList(obj) {
      http
        .get("/attendanceRecord/list", {
          ...search,
          pageNum: 1,
          pageSize: 10,
          attendanceGroup: this.groupId,
          monthString: this.timevalue,
        })
        .then((res) => {
          if (res.code == 200) {
          } else {
            this.$message.error(res.message);
          }
        });
    },
    formatTime(tt) {
      if (tt) {
        if (tt.length <= 0) return "";
        let str = "";
        for (let i = 0; i < tt.length; i++) {
          let str2 =
            this.secondsToHourMin(tt[i].clockData.beginDaySecond) +
            "-" +
            this.secondsToHourMin(tt[i].clockData.endDaySecond);
          if (str == "") str = str2;
          else str = str + "  " + str2;
        }
        return str;
      }
    },
    searchClassName() {
      this.getClass({
        likeString: this.arrangeName,
      });
    },
    editCycle() {
      this.cycleDialog = true;
      this.cList = JSON.parse(JSON.stringify(this.selectedClassSelection));
      // this.selectedClassSelection.map((item) => {
      //   if (item.attendanceArrangeGetVO)
      //     this.cList.push(item.attendanceArrangeGetVO.id)
      // })
    },
    closeCycle() {
      this.cycleDialog = false;
      this.cyclekey = Math.random();
    },
    closeAdd() {
      this.addDialog = false;
      this.cycleDialog = true;
    },
    openAdd() {
      this.cycleDialog = false;
      this.addDialog = true;
      this.groupId = this.$route.query.id;
    },

    // 页数改变
    changePage(e) {
      if (this.seaName == "") {
        this.getPeopleList({ pageNum: e });
      } else this.getPeopleList({ name: this.seaName, pageNum: e });
    },
    closeAddCycle(arr) {
      if (arr) {
        let dList = this.ldata;
        this.deleteCycle(dList, arr);

        this.cyclekey = Math.random();
        this.getCycle();
      }
      this.cycleDialog = false;
    },

    deleteCycle(arrList, arr2) {
      for (let i = 1; i < arrList.length; i++) {
        for (let j = this.today + 1; j < arrList[i].length; j++) {
          if (arrList[i][j] !== "") {
            let item = arr2.filter((item2) => {
              return item2 == arrList[i][j].cId;
            });
            if (item.length > 0) {
              this.cleanCell(i, j, arrList[i][j]);
            }
          }
        }
      }
    },
    deleteCycle2(dId) {
      this.selectedcycleSelection = this.selectedcycleSelection.filter(
        (item) => {
          return item.id != dId;
        }
      );
      // this.getCycle()
    },
    affirmDiglog2() {
      this.addDialog = false;
      this.cycleDialog = true;
    },
    //获取天数
    getDaysInMonth(year, month) {
      let date = new Date(year, month, 1);
      return new Date(date.getTime() - 864e5).getDate();
    },

    //单元格背景颜色
    formatColor(items, index) {
      if (this.isBefore || items.arrange == -3) {
        return "sch_table_btn2 sch_table_cell_color_r";
      } else if (!this.isBefore && this.isToMon && index <= this.today) {
        return "sch_table_btn2 sch_table_cell_color_r";
      } else if (items != "" && items.arrange != -1) {
        return "sch_table_btn sch_table_cell_color";
      } else if (items != "" && items.arrange == -1) {
        return "sch_table_btn sch_table_cell_color_r";
      } else {
        return "sch_table_btn";
      }
    },
    formatDay(day) {
      let arr = day.split("");
    },
    isDisable(index) {
      if (this.isBefore) {
        return true;
      } else if (!this.isBefore && this.isToMon && index <= this.today) {
        return true;
      } else {
        return false;
      }
    },
    // 判断是否是之前的日期
    isBeforeDay() {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      const cYear = this.timevalue.split("-")[0];
      const cMon = this.timevalue.split("-")[1];
      if (cYear < year) {
        this.isBefore = true;
      } else if (cYear == year && cMon < month) {
        this.isBefore = true;
      } else if (cYear == year && cMon == month) {
        this.isToMon = true;
        this.isBefore = false;
      } else {
        this.isBefore = false;
        this.isToMon = false;
      }
      this.today = day;
    },
    toMonth() {
      const today = new Date();
      const year = today.getFullYear();
      let month = today.getMonth() + 1;
      let days = this.getDaysInMonth(year, month);

      if (month < 10) month = "0" + month;
      this.timevalue = year + "-" + month;
      let arr = new Array(days + 1).fill("");
      let arr2 = new Array(days + 1).fill("");
      arr[0] = "姓名";
      arr2[0] = "班次";
      for (let i = 1; i < arr.length; i++) {
        var date = new Date(year + "-" + month + "-" + i);
        const dayArr = ["日", "一", "二", "三", "四", "五", "六"];
        var dayOfWeek = dayArr[date.getDay()];
        arr[i] = {
          index: i,
          day: dayOfWeek,
        };
        arr2[i] = {
          index: i,
          day: dayOfWeek,
        };
      }
      this.ldata.push(arr);
      this.ldataCopy.push(arr);
      this.statisticsData.push(arr2);
    },
    // 秒转换成时间
    secondsToHourMin(seconds) {
      if (seconds >= 86400) {
        seconds = seconds - 86400;
      }
      // 将秒数转换为分钟
      let minutes = Math.floor(seconds / 60);
      // 将分钟转换为小时和分钟
      let hours = Math.floor(minutes / 60);
      let minutesRemainder = minutes % 60;

      // 格式化小时和分钟
      hours = hours < 10 ? "0" + hours : hours;
      minutesRemainder =
        minutesRemainder < 10 ? "0" + minutesRemainder : minutesRemainder;

      // 返回时分格式
      return hours + ":" + minutesRemainder;
    },
    getAbsentValues(arr1, arr2) {
      let res = [];
      arr2.forEach((item, idx) => {
        if (arr1.indexOf(item) == -1) res.push(item);
      });
      return res;
    },
  },
};
</script>
<style scoped>
.schedule {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 16px 20px;
  background: #f4f7fc;
}

.schedule_head {
  font-size: 14px;
}

.schedule_wrapper {
  width: calc(100vw - 290px);
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 1px 4px 1px rgba(21, 34, 50, 0.08);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  margin-top: 16px;
  padding-bottom: 10px;
  position: relative;
}

.schedule_search_wrapper {
  display: flex;
  align-items: center;
  padding: 20px;
  width: auto;
  border-bottom: 1px solid rgba(207, 212, 232, 0.5);
}

.title_icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.group_title {
  font-size: 16px;
  color: #5c5c5c;
}

.group_back {
  font-size: 16px;
  color: #1a1a1a;
  display: inline-block;
}

.schedule_back {
  font-size: 16px;
  color: #1a1a1a;
}

.schedule_search_wrapper ::v-deep .el-icon-back {
  font-size: medium;
}

.schedule_add {
  margin-right: 10px;
  margin-left: auto;
}

.schedule_table_wrapper {
  padding: 20px 18px 20px 20px;
  width: 100%;
  font-size: 16px;
  flex: 1;
  box-sizing: border-box;
}

.sch_title {
  margin-bottom: 14px;
  font-size: 16px;
  display: flex;
}

.sch_title_label {
  margin-right: 20px;
  color: #1a1a1a;
  /* width: 80px; */
  white-space: nowrap;
}

.sch_title_d {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: auto;
}

.sch_title_span {
  height: 30px;
  line-height: 30px;
  background: rgba(0, 88, 255, 0.1);
  margin-right: 6px;
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 10px;
}

.sch_search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.sch_search_item {
  margin-right: 10px;
}

.approval_search {
  width: 243px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  display: flex;
  align-items: center;
  padding-right: 12px;
  box-sizing: border-box;
  justify-content: space-between;
}

.schedule_table {
  font-size: 16px;
  width: 100%;
}
.schedule_table2 {
  font-size: 16px;
  margin-top: 20px;
}

table {
  border-collapse: collapse;
  font-size: 16px;
  width: 100%;
  table-layout: fixed;
  border: none !important;
}
table tr {
  display: flex;
}
table tr td {
  display: flex;
  flex: 1;
}
.sch_table tr td:first-child div {
  width: 96px !important;
  height: 45px;
  text-align: center;
  line-height: 45px;
  font-size: 12px;
}

.sch_table tr:first-child td:not(:first-child) {
  height: 45px;
  flex: 1;
  text-align: center;
}

.sch_table tr:first-child td:not(:first-child) div {
  padding-top: 11%;
  height: 55px;
  text-align: center;
}
.specia_not {
  width: 100%;
  height: 100%;
}
.sch_table_btn {
  cursor: pointer;
  height: 50px;
  width: 45px;
  padding: 2px;
  /* border: none; */
  background: none;
  vertical-align: center;
  white-space: normal;
  word-break: break-all;
  overflow: hidden;
  color: #1a1a1a;
  border-radius: 4px;
  border: 2px solid white;
}

.sch_table_btn2 {
  cursor: pointer;
  height: 60px;
  width: 65px;
  /* padding: 2px; */
  border: none;
  background: none;
  vertical-align: center;
  white-space: normal;
  word-break: break-all;
  overflow: hidden;
  color: #1a1a1a;
  border-radius: 0;
  border: 2px solid transparent;
}
.bottom-popover {
  width: 100%;
  height: 100%;
  font-size: 12px;
}
.sch_table_text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 2px; */
  border: none;
  background: none;
  vertical-align: center;
  white-space: normal;
  word-break: break-all;
  overflow: hidden;
  color: #1a1a1a;
}
.sch_table_text2 {
  width: 60px;
  cursor: pointer;
  /* padding: 2px; */
  border: none;
  background: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1a1a1a;
}

.sch_table tr:not(:first-child) td:not(:first-child) {
  text-align: center;
  vertical-align: center;
  white-space: normal;
  word-break: break-all;
  overflow: hidden;
  height: 50px;
}

.sch_table ::v-deep .el-button {
  width: 100%;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
}

/* 鼠标点击 */
.sch_table button:hover {
  height: 100%;
  width: 100%;
  background: rgba(0, 88, 255, 0.1);
  border: 2px solid #0058ff !important;
  border-radius: 0;
}
.sch_table_cell {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #d0d0d0;
  position: relative;
}

.sch_table_cell_color {
  background: rgba(0, 88, 255, 0.1);
}

.sch_table_cell .el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  width: 100%;
  height: 100%;
}

.sch_box {
  width: 12px;
  height: 12px;
  background-color: #f0142f;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* Internet Explorer */
  -moz-transform: rotate(45deg);
  /* Firefox */
  -webkit-transform: rotate(45deg);
  /* Safari 和 Chrome */
  -o-transform: rotate(45deg);
  /* Opera */
  position: absolute;
  right: -6px;
  top: -6px;
}

table {
  border: 1px solid #d0d0d0;
}

.sch_table ::v-deep .el-popover {
  width: 350px !important;
  padding: 0 !important;
  font-size: 16px;
}

/* .aaa {
  height: 300px;
  overflow: auto;
} */

.sc_pop_tab ::v-deep .el-tabs__nav-scroll {
  padding: 24px 30px;

  border: 0;
}

.sc_pop_tab ::v-deep .el-tabs__active-bar {
  width: 80px;
  height: 4px;
  background: #0058ff;
  border-radius: 10px;
  opacity: 1;
  bottom: -23px;
}

.sc_popover ::v-deep .el-tabs__nav-wrap::after {
  height: 1px !important;

  background-color: #cfd4e8;
}

.sc_pop_tab ::v-deep .el-tabs__item {
  padding: 0 40px;
}

.sc_pop_tab ::v-deep .el-tabs__content {
  font-size: 16px;
  color: #1a1a1a;
}

.sc_pop_tab_list {
  padding-left: 22px;
  padding-right: 22px;
}

.sc_pop_tab_bottom {
  border-top: 1px solid #cfd4e8;
  text-align: center;
  height: 70px;
  line-height: 70px;
  color: #1a1a1a;
}

.sc_pop_tab_bottom2 {
  height: 70px;
}

.sc_tab_right_tip {
  margin-bottom: 18px;
  color: #9a9a9a;
}

.sc_tab_right_item {
  display: inline-block;
  width: auto;
  height: 26px;
  background: rgba(0, 88, 255, 0.1);
  color: #1a1a1a;
  margin-bottom: 26px;
  padding: 3px 10px;
  cursor: pointer;
}

.sc_popover_top {
  font-size: 15px;
}

.sc_tab_right_relax {
  background: #e8e8e8;
}

.drawer_title {
  display: flex;
  align-items: center;
}

.drawer_title_filter {
  font-size: 18px;
  margin-left: 20px;
  color: #1a1a1a;
}

.drawer_confirm_f {
  background: #0058ff;
  font-size: 15px;
  width: 100px;
  height: 40px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}

.drawer_search {
  width: 243px;
  margin-bottom: 20px;
}

::v-deep .drawer_cycle .el-table__header {
  table-layout: inherit;
}

::v-deep .drawer_cycle .el-drawer__body {
  overflow: hidden;
}

::v-deep .drawer_table .el-table__cell {
  font-size: 15px;

  color: #5c5c5c;
}

::v-deep .drawer_table .el-table__body-wrapper {
  /* overflow: hidden; */
  overflow-y: auto;
  height: 37.5rem;
}

.drawer_confirm {
  margin: 0 0 0 auto;
}

::v-deep .el-table-column--selection .cell {
  padding-right: 0;
}

::v-deep .el-input__suffix {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 12px;
}

.newpagination {
  display: flex;
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
  margin-top: 20px;
  justify-content: space-between;
}

.newpagination_text {
  font-size: 16px;
  color: #5c5c5c;
}

::v-deep .newpagination .el-pagination {
  margin-right: 0;
}
.specia_cell {
  font-size: 12px;
  height: 40px !important;
}
.specia_cell div {
  height: 20px !important;
}
.sch_table_cell2 {
  height: 40px;
}
.sch_search_left {
  display: flex;
  align-items: center;
}
.sch_search_right {
  width: 94px;
  height: 36px;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #d5d7e3;
  font-size: 14px;
  color: #7e84a3;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
}
.sch_table_cell_color_r {
  background: #e8e8e8 !important;
}
.single_seletc {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .single_seletc .el-checkbox {
  display: flex;
  align-items: center;
}
::v-deep .single_seletc .el-checkbox__label {
  width: 60px;
  font-size: 12px;
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 超出容器的文本隐藏 */
  text-overflow: ellipsis;
}
.specia_not2 {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
<!-- <style >

  