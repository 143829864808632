<template>
  <div class="distribution">
    <div class="distribution_h">
      <div>人员下发</div>
      <img
        src="@/assets/structure/close.png"
        class="distribution_icon"
        @click="close"
      />
    </div>
    <div class="distribution_content">
      <div class="distribution_l">
        <div class="distribution_l_title">请选择人员</div>
        <div class="distribution_l_input">
          <el-input
            placeholder="输入员工姓名、工号"
            @keyup.enter.native="seachResult"
            v-model="search"
          >
          </el-input>
          <img
            src="@/assets/structure/delete.png"
            class="searchicon"
            @click="clearup"
            v-if="delectIcon"
          />
          <img
            src="@/assets/structure/search.png"
            class="searchicon"
            @click="seachResult"
          />
        </div>
        <div class="distribution_checkbox">
          <div class="crumbs" v-if="!isSearchResult">
            <div
              v-for="(item, index) in crumbsList"
              :key="index"
              @click="breadDept(item, index)"
            >
              {{ item.name }}<span>/</span>
            </div>
          </div>
          <div v-else>搜索结果</div>
          <div
            class="distribution_checkbox_option"
            v-for="item in deptList"
            :key="item.id"
          >
            <el-checkbox
              class="selectItem"
              v-model="item.ischecked"
              @change="selectDept(item)"
              >{{ item.name }}</el-checkbox
            >
            <el-link
              type="primary"
              :disabled="item.ischecked"
              @click="querySubordinates(item)"
              v-if="!item.userId && !item.jobDepartment"
              >下级</el-link
            >
          </div>
        </div>
        <div class="selectoption">
          <div class="selectoption_t">
            <div class="selectoption_t_all">
              已选
              <div :class="[checkList.length > 0 ? 'discolour' : '']">
                ({{ checkList.length }})
              </div>
            </div>
            <el-link type="primary" @click="clearall">清除</el-link>
          </div>
          <div class="selectoption_all">
            <div
              class="selectoption_single"
              v-for="item in checkList"
              :key="item.id"
            >
              <div>{{ item.name }}</div>
              <img
                @click="delectDept(item)"
                src="@/assets/structure/optionclose.png"
                class="selectoption_close"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="distribution_r">
        <div class="distribution_l_title">请选择设备</div>
        <div class="distribution_l_input">
          <el-input
            placeholder="输入设备名称、SN序列号"
            @keyup.enter.native="seachequResult"
            v-model="searchEqu"
          >
          </el-input>
          <img
            src="@/assets/structure/delete.png"
            class="searchicon"
            @click="clearupEqu"
            v-if="delectIcon2"
          />
          <img
            src="@/assets/structure/search.png"
            class="searchicon"
            @click="seachequResult"
          />
        </div>
        <div class="distribution_r_table">
          <el-table
            height="15.6rem"
            ref="multipleTable"
            :header-cell-style="getRowClass"
            :data="tableData"
            :row-key="getRowKey"
            :cell-style="cellClass"
            tooltip-effect="dark"
            class="distribution_r_table_content"
            @selection-change="handleSelectionChange"
          >
            <template slot="empty">
              <div>
                <img src="@/assets/nodata.png" class="anodata" />
                <div>无数据</div>
              </div>
            </template>
            <el-table-column
              type="selection"
              width="55"
              :reserve-selection="true"
            >
            </el-table-column>
            <el-table-column prop="name" label="设备名称"> </el-table-column>
            <el-table-column prop="sn" label="SN序列号"> </el-table-column>
          </el-table>
        </div>
        <div class="selectoption">
          <div class="selectoption_t">
            <div class="selectoption_t_all">
              已选
              <div :class="[checkdeptList.length > 0 ? 'discolour' : '']">
                ({{ checkdeptList.length }})
              </div>
            </div>
            <el-link type="primary" @click="delectDevice">清除</el-link>
          </div>
          <div class="selectoption_all">
            <div
              class="selectoption_single"
              v-for="item in checkdeptList"
              :key="item.id"
            >
              <div>{{ item.name }}</div>
              <img
                @click="delectLittle(item)"
                src="@/assets/structure/optionclose.png"
                class="selectoption_close"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="distribution_bto">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="comfin">确认</el-button>
    </div>
  </div>
</template>

<script>
import http from "../../../../utils/http";
export default {
  data() {
    return {
      search: "", //搜索内容
      searchEqu: "", //搜索内容(设备)
      isSearchResult: false,
      topleave: [], //部门列表
      delectIcon: false, //删除按钮(部门)
      delectIcon2: false, //删除按钮(设备)
      crumbsList: [
        {
          name: "全部",
          departmentId: null,
        },
      ], //面包屑
      crumbscopyList: [
        {
          name: "全部",
          departmentId: null,
        },
      ], //深层面包屑
      tableData: [],
      crumbscopyListcopy: [
        {
          name: "全部",
          departmentId: null,
        },
      ],
      search: "", //搜索内容
      deptList: [], //部门列表
      checkList: [], //所选的列表
      checkPersion: [], //所选的人员
      checkDepartment: [], //所选的部门
      checkdeptList: [], //所选设备
    };
  },

  created() {
    this.getEquipment();

    // 获取企业名称
    var company = localStorage.getItem("department");
    this.topleave = JSON.parse(company);

    this.deptList.push({
      name: this.topleave[0].departmentName,
      departmentId: "",
      ischecked: false,
    });
  },

  watch: {
    search(newName, oldName) {
      if (newName == "") {
        this.isSearchResult = false;
        this.delectIcon = false;

        this.deptList = [];

        // 获取企业名称
        var company = localStorage.getItem("department");
        this.topleave = JSON.parse(company);

        this.deptList.push({
          name: this.topleave[0].departmentName,
          departmentId: "",
          ischecked: false,
        });

        var json = JSON.stringify(this.crumbscopyListcopy);

        this.crumbsList = JSON.parse(json);
      } else {
        this.delectIcon = true;
      }
    },

    // 设备监听
    searchEqu(newName, oldName) {
      if (newName == "") {
        this.getEquipment();
        this.delectIcon2 = false;
      } else {
        this.delectIcon2 = true;
      }
    },
  },

  methods: {
    // 表头背景颜色变色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#F5F6FA;color:#5C5C5C;fontSize:14px";
      }
    },
    cellClass({ rowIndex, columnIndex }) {
      return "fontSize:14px";
    },
    // 搜索后表格勾选不取消
    getRowKey(row) {
      return row.id;
    },
    // 关闭弹窗
    close() {
      this.$emit("close");
    },
    // 获取部门列表
    getDeptlist(id) {
      http
        .get("/department/push_list", {
          departmentId: id,
        })
        .then((res) => {
          if (res.code == 200) {
            var index = this.crumbscopyList.findIndex(
              (item) => item.departmentId == id
            );
            res.data.map((item) => {
              item.ischecked = false;
            });

            res.data.map((item) => {
              item.ischecked = this.judgeStaus(item);
            });
            this.crumbscopyList[index].child = res.data;
            this.deptList = res.data;
          }
        });
    },

    // 获取搜索结果
    seachResult() {
      if (this.search == "") {
        return;
      }
      http
        .get("/department/push_list", {
          userName: this.search,
        })
        .then((res) => {
          if (res.code == 200) {
            this.isSearchResult = true;

            res.data.map((item) => {
              item.ischecked = this.judgeStaus(item);
            });
            this.deptList = res.data;
          }
        });
    },

    // 清空部门搜索
    clearup() {
      this.search = "";
    },

    // 点击面包屑部门
    breadDept(item, index) {
      this.crumbsList.splice(index + 1, this.crumbsList.length - 1);

      var index = this.checkDepartment.findIndex(
        (item) => item.departmentId == ""
      );

      if (item.name == "全部") {
        if (index > -1) {
          this.deptList = [
            {
              name: this.topleave[0].departmentName,
              departmentId: null,
              ischecked: true,
            },
          ];
        } else {
          this.deptList = [
            {
              name: this.topleave[0].departmentName,
              departmentId: "",
              ischecked: false,
            },
          ];
        }
      } else {
        this.getDeptlist(item.departmentId);
      }
    },

    // 查询下级
    querySubordinates(item) {
      this.crumbsList.push({
        name: item.name,
        departmentId: item.departmentId,
      });
      // 深层次的面包屑
      this.crumbscopyList.push({
        name: item.name,
        departmentId: item.departmentId,
      });

      this.getDeptlist(item.departmentId);
    },
    // 判断是否是选中状态
    judgeStaus(value) {
      // 如果是人员
      if (value.userId || value.jobDepartment) {
        var index = this.checkPersion.findIndex(
          (item) => item.userId == value.userId
        );
        if (index > -1) {
          return true;
        } else {
          return false;
        }
      } else {
        var index = this.checkDepartment.findIndex(
          (item) => item.departmentId == value.departmentId
        );
        if (index > -1) {
          return true;
        } else {
          return false;
        }
      }
    },
    // 选择部门
    selectDept(item) {
      item.path = [];
      // 处理所选的部门或人员,加路径部门
      this.crumbsList.map((value) => {
        item.path.push(value.departmentId);
      });

      // 如果是选中状态
      if (item.ischecked) {
        // 先判断是否在这路径上(人)
        if (item.userId) {
          // 如果是人
          this.checkPersion.push(item);
        } else {
          var arr = [];
          var arr2 = [];
          // 部门
          for (let index = 0; index < this.checkDepartment.length; index++) {
            for (
              let cindex = 0;
              cindex < this.checkDepartment[index].path.length;
              cindex++
            ) {
              if (
                this.checkDepartment[index].path[cindex] == item.departmentId
              ) {
                arr.push(this.checkDepartment[index]);
              }
            }
          }
          for (let index = 0; index < this.checkPersion.length; index++) {
            for (
              let cindex = 0;
              cindex < this.checkPersion[index].path.length;
              cindex++
            ) {
              if (this.checkPersion[index].path[cindex] == item.departmentId) {
                arr2.push(this.checkPersion[index]);
              }
            }
          }
          this.checkDepartment.push(item);

          let ids = arr.map((item) => item.departmentId);
          this.checkDepartment = this.checkDepartment.filter(
            (item) => !ids.includes(item.departmentId)
          );

          let ids2 = arr2.map((item) => item.departmentId);
          this.checkPersion = this.checkPersion.filter(
            (item) => !ids2.includes(item.departmentId)
          );
        }
      } else {
        // 取消选中(如果是人员)
        if (item.userId) {
          var index = this.checkPersion.findIndex(
            (value) => value.userId == item.userId
          );
          if (index > -1) {
            this.checkPersion.splice(index, 1);
          }
        } else {
          // 如果是部门
          var index = this.checkDepartment.findIndex(
            (value) => value.departmentId === item.departmentId
          );
          if (index > -1) {
            this.checkDepartment.splice(index, 1);
          }
        }
      }
      this.checkList = [...this.checkDepartment, ...this.checkPersion];
    },

    // 删除所选部门
    delectDept(item) {
      if (item.userId) {
        var onslie = this.checkPersion.findIndex(
          (value) => value.userId == item.userId
        );
        this.checkPersion.splice(onslie, 1);

        // 如果在当前列表
        var index = this.deptList.findIndex(
          (value) => value.userId == item.userId
        );
        if (index > -1) {
          this.deptList[index].ischecked = false;
        }
      } else {
        var onslie = this.checkDepartment.findIndex(
          (value) => value.departmentId == item.departmentId
        );
        this.checkDepartment.splice(onslie, 1);

        // 如果在当前列表
        var index = this.deptList.findIndex(
          (value) => value.departmentId == item.departmentId
        );
        if (index > -1) {
          this.deptList[index].ischecked = false;
        }
      }

      this.checkList = [...this.checkPersion, ...this.checkDepartment];
    },

    // 清除所有选择的部门
    clearall() {
      this.deptList.map((item) => (item.ischecked = false));
      this.checkList = [];
      this.checkDepartment = [];
      this.checkPersion = [];
    },

    // 获取全部设备
    getEquipment() {
      http
        .get("/device/search", {
          pageNum: 1,
          pageSize: 999,
        })
        .then((res) => {
          if (res.code == 200) {
            this.tableData = res.data.list;
          }
        });
    },
    // 搜索设备
    seachequResult() {
      http
        .get("/device/search", {
          pageNum: 1,
          pageSize: 999,
          nameAndSNLike: this.searchEqu,
        })
        .then((res) => {
          if (res.code == 200) {
            this.tableData = res.data.list;
          }
        });
    },
    // 清空设备搜索
    clearupEqu() {
      this.searchEqu = "";
    },
    // 设备选择
    handleSelectionChange(val) {
      this.checkdeptList = val;
    },
    // 清除所选设备
    delectLittle(item) {
      var citem = this.tableData.find((v) => v.id == item.id);
      if (citem) {
        this.$refs.multipleTable.toggleRowSelection(citem);
      } else {
        this.$refs.multipleTable.toggleRowSelection(item);
      }
    },
    // 清除全部设备
    delectDevice() {
      this.checkdeptList = [];
      this.$refs.multipleTable.clearSelection();
    },

    // 确认下发
    comfin() {
      if (this.checkList.length == 0) {
        this.$message.error("请先选择下发的人员");
        return;
      }

      if (this.checkdeptList.length == 0) {
        this.$message.error("请先选择下发的设备");
        return;
      }
      // 获取企业名称
      var user = localStorage.getItem("userinfo");
      var company = JSON.parse(user);
      var obj = {};

      var arr = []; //设备
      var arr2 = []; //部门
      var arr3 = []; //人员
      this.checkdeptList.map((item) => {
        arr.push(item.id);
      });
      this.checkDepartment.map((item) => {
        arr2.push(item.departmentId);
      });
      this.checkPersion.map((item) => {
        arr3.push(item.userId);
      });

      var index = this.checkList.findIndex((item) => item.departmentId == "");
      if (index > -1) {
        // 如果存在的话
        obj = {
          companyId: company.jobCompany,
          deviceIdList: arr,
        };
      } else {
        obj = {
          departmentIdList: arr2,
          deviceIdList: arr,
          userIdList: arr3,
        };
      }
      http.post("/user/push", obj).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功");
          this.close();
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style scoped>
.distribution_h {
  height: 73px;
  padding: 0 24px;
  box-sizing: border-box;
  display: flex;
  color: #1a1a1a;
  align-items: center;
  font-size: 18px;
  justify-content: space-between;
  border-bottom: 1px solid #cfd4e8;
}
.distribution_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.distribution_content {
  padding: 30px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.distribution_l {
  width: 455px;
  height: 583px;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #d0d0d0;
}
.distribution_r {
  width: 455px;
  height: 583px;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #d0d0d0;
}
.distribution_l_title {
  font-size: 16px;
  color: #1a1a1a;
}
.distribution_l_input {
  color: #0058ff;
  display: flex;
  align-items: center;
  border: 1px solid #d0d0d0;
  padding-right: 12px;
  box-sizing: border-box;
  margin: 14px 0 30px;
}
.distribution_l_input:focus-within {
  border-color: #0096fa;
}
.distribution_checkbox {
  height: 250px;
  overflow-y: scroll;
}
.distribution_checkbox::-webkit-scrollbar {
  display: none;
}
.distribution_checkbox_option {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 27px;
  justify-content: space-between;
}
.selectoption {
  margin-top: 30px;
}
.selectoption_t {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.selectoption_t_all {
  color: #1a1a1a;
  display: flex;
  align-items: center;
}
.discolour {
  color: #0058ff;
}
.selectoption_all {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 120px;
  overflow-y: scroll;
  margin-top: 20px;
}
.selectoption_all::-webkit-scrollbar {
  display: none;
}
.selectoption_single {
  background: #e0ecff;
  border-radius: 4px;
  padding: 6px;
  color: #0058ff;
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-right: 16px;
  margin-bottom: 5px;
}
.selectoption_close {
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-left: 6px;
}
.distribution_r_table {
  width: 100%;
  height: 250px;
}
.distribution_r_table_content {
  width: 100%;
}
.distribution_bto {
  display: flex;
  align-items: center;
  justify-content: center;
}
.crumbs {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  color: #5c5c5c;
}
.crumbs span {
  margin: 0 5px;
}
::v-deep .distribution_l_input .el-input__inner {
  color: #0058ff;
}
::v-deep .el-link.is-disabled {
  color: #9a9a9a !important;
}
.selectItem {
  display: flex;
  align-items: center;
}
::v-deep .selectItem > .el-checkbox__label {
  font-size: 16px;
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
::v-deep .selectItem .el-checkbox__inner {
  width: 16px;
  height: 16px;
}
::v-deep .selectItem .el-checkbox__inner::after {
  left: 5px;
}
.anodata {
  width: 80px;
  height: 80px;
  margin-top: 50px;
}
::v-deep .el-input__suffix {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
